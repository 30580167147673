// react imports
import React, { memo, useMemo, useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'


import video_play from '../../assets/media/icons/video-play.png'
import top_ten from '../../assets/media/icons/Top10.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import CarouselComponent from '../carousel/CarouselComponent'
import CarouselComponentTop from '../carousel/CarouselComponentTop'
import CarouselComponentTrainer from '../carousel/CarouselComponentTrainer'

import CategoryModal from "../modals/CategoryModal";

import { MDBContainer,
         MDBLoadingManagement
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const VideosPage = ({ isPageRender }) => {

  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();
  const basicRef = useRef(null);
  const location = useLocation();
  

  // STATES
  const [categoryList, setCategoryList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [newVideoList, setNewVideoList] = useState([])
  const [bookmarkList, setBookmarkList] = useState([])
  const [categoryName, setCategoryName] = useState(null)
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [trainerModalVisible, setTrainerModalVisible] = useState(false);
  const [trainer, setTrainer] = useState({});
  const [trainerList, setTrainerList] = useState([]);
  const [playing, setPlaying] = useState(false)
  const [randomNumber, setRandomNumber] = useState(Math.floor((Math.random() * 10))+1)
  
  
  const [startfont, setStartfont] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
 
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  useEffect(() => {
    setTimeout(() => {setStartfont(true) }, 200)
  }, []);

  const onGetVideos = () => {
    context.interAxios.get('/video', function(response) {
        setVideoList(response.data.content)
        var set = new Set(response.data.content.map(k => {return (k.category)}))
        setCategoryList(Array.from(set).sort())
        console.log("lefutok")
    });
  }

  const onGetNewVideos = () => {
    context.interAxios.get('/video/latest', function(response) {
        setNewVideoList(response.data)
    });
  }
  
  
  useEffect(() => {
    

    onGetVideos();
    onGetNewVideos();
    context.interAxios.get('/trainer', function(response) {
      setTrainerList(response.data)
    });
    context.interAxios.get('/bookmark', function(response) {
      context.onSetBookmarkList(response.data)
    });
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  const getTrainer = (trainer) => {
    setTrainer(trainer);
    navigate('/trainerdetail', { state: { trainer: trainer, videoList: videoList, trainerList: trainerList}}) 

  }

  
  useEffect(() => {
    setTimeout(() => {
        setPlaying(true);
    }, 500)
    setIsRender(false)
  }, []);

  const onPutVideoToBookmark = (id) => {
    context.interAxios.put('/bookmark/' + id, function(response) {
        context.interAxios.get('/bookmark', function(response) {
          context.onSetBookmarkList(response.data)
        });
        onGetVideos();
        onGetNewVideos();
    });
  }

  const onDeleteVideoFromBookmark = (id) => {
      context.interAxios.delete('/bookmark/' + id, function(response) {
          context.interAxios.get('/bookmark', function(response) {
            context.onSetBookmarkList(response.data)
          });
          onGetVideos();
          onGetNewVideos();
      });
  }
  
  
  return (
    <MDBContainer style={{maxWidth: "1920px", marginTop: "-106px"}} className="landing-page">
      
        <section className="first-section d-none d-lg-block" id="">
          <div  style={{maxWidth: videoWidht, height: videoWidht*0.5625 +"px"}}>
            {!playing ?  <> 
              <div ref={basicRef} className="loading-manager" style={{ zIndex: "3029", height: "100%" }}>
                  <MDBLoadingManagement  loadingText="Loading" parentRef={basicRef} />
              </div>
            </> : <>     
                <ReactPlayer url={newVideoList[randomNumber-1]?.fullUrl} 
                    width= {videoWidht} 
                    height={videoWidht*0.5625 +"px"} 
                    controls={false} 
                    volume={0.1}
                    muted={true}
                    playing={playing}
                    loop={true                   }
                />
            
            <div className="first-section-div">
              <span className="title">{newVideoList[randomNumber-1]?.name}</span>
              <div className="top-video">
                <img src={top_ten} alt="top"/>
                <span>{"Top " + (randomNumber-1) + "th today"}</span>
              </div>
              <span className="description">{newVideoList[randomNumber]?.description}</span>
              <button type="button" onClick={() => { navigate('/videoplay', { state: { fullUrl: newVideoList[randomNumber-1]?.fullUrl } })}}><img src={video_play} alt="top"/><span>PLAY</span></button>
            </div>
            </>}
            </div>
        </section>

        <section className="videotar">
          <div id="videotar" style={{overflow: "hidden"}}>
    
            {newVideoList.length > 0 && (
            <div style={{position: "relative", marginBottom: "10px"}} id={"carousel-0"}>
                <div className="category-title">Top quick workouts</div>  
                <CarouselComponentTop setIsRender={setIsRender} isRender={isRender} videoList={newVideoList.filter((k, n) => n < 9 )} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
            </div>)}

            {context.getState()?.bookmarkList?.length > 0 && (
              <div style={{position: "relative", marginTop: "20px", marginBottom: "20px"}} id={"carousel-a"}>
                  <div className="category-title" 
                  >My favourites</div>  
                  <CarouselComponent category={"My favourites"} setIsRender={setIsRender} isRender={isRender} videoList={context.getState()?.bookmarkList} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
              </div>
            )}

  	        {categoryList.filter((k,n) => n < 2).map((k, n) => {return (
            <div key={n} style={{position: "relative", marginBottom: "20px"}} id={"carousel-" + n+1}>
              <button className="category-title d-none d-lg-flex" 
                      onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: videoList}})}

              >{k !== null ? k : "General"}<span className="link-text"> Explore all of them</span></button>  
              <div className="category-title d-block d-lg-none" 
              >{k !== null ? k : "General"}</div>  
              <CarouselComponent category={k !== null ? k : null} videoList={videoList} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
          
            </div>
            
        )})}
        {trainerList && (
          <div style={{position: "relative", marginBottom: "35px"}} id={"carousel-0"}>
            <button className="category-title" >Gymflix trainers</button>  
            <CarouselComponentTrainer getTrainer={getTrainer} trainerList={trainerList} />
          </div>
        )}
        

        {categoryList.filter((k,n) => n >1 && n < 4).map((k, n) => {return (
            <div key={n} style={{position: "relative", marginBottom: "15px"}} id={"carousel-" + n+1}>
                <button className="category-title d-none d-lg-flex" 
                      onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: videoList}})}

              >{k !== null ? k : "General"}<span className="link-text"> Explore all of them</span></button>  
              <div className="category-title d-block d-lg-none" 
              >{k !== null ? k : "General"}</div>   
              <CarouselComponent category={k !== null ? k : null} videoList={videoList} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
          
            </div>
            
        )})}

        {newVideoList.length > 0 && (
          <div style={{position: "relative", marginTop: "15px", marginBottom: "10px"}} id={"carousel-0"}>
              <button className="category-title">Top quick workouts</button>  
              <CarouselComponentTop videoList={newVideoList.filter(k => k.name !== "Nyereményjáték eredményhirdetés").filter((k, n) => n > 8 && n < 18)} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
          </div>)}

        {categoryList.filter((k,n) => n >3).map((k, n) => {return (
          <div key={n} style={{position: "relative", marginBottom: "15px"}} id={"carousel-" + n+1}>
              <button className="category-title d-none d-lg-flex" 
                      onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: videoList}})}

              >{k !== null ? k : "General"}<span className="link-text"> Explore all of them</span></button>  
              <div className="category-title d-block d-lg-none" 
              >{k !== null ? k : "General"}</div> 
            <CarouselComponent category={k !== null ? k : null}  videoList={videoList} trainerList={trainerList} startfont={startfont} setStartfont={setStartfont} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
        
          </div>
        )})}
          </div>
        </section>
    </MDBContainer>


  );
};

export default  VideosPage;

