import React, { Component, useContext  } from "react";
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../../../AppContext';

import DeleteModal from '../../Modals/DeleteModal.js';

import { MDBContainer,
         MDBBtn,
         MDBIcon,
         MDBSwitch,
         MDBDatatable,
         MDBAlert,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';


class NewsletterTable extends Component {

  constructor(props) {
    super(props);
    this.state = {newsletterList:[]}
  }

  componentDidMount() {
    this.onGetAllNewsletter();
  }


  onGetAllNewsletter = () => {
    let self = this;
    this.props.context.interAxios.get('/admin/newsletter', function(response) {
      self.setState({newsletterList: response.data});
    });
  }


  onDeleteNewsletterById = (id) => {
    this.setState({alert: true, alertText:"Az emailt törölte a listáról"})
    let self = this;
    this.props.context.interAxios.delete('/admin/newsletter/' + id, function(response) {
      self.onGetAllNewsletter();
    });
  }

  handleSwitchChange = (id, active) => {
    let self = this;
    this.props.context.interAxios.patch('/admin/newsletter/' + id, { "handled": !active },
     function(response) {
      self.onGetAllNewsletter();
    })

  };


  showDeleteNewsletterByIdModal = (id) => {
    this.setState({newsletterId: id, showDeleteModal: true, status: "Email törlése", modalText: "Valóban törölni szeretné az emailt a listából?"})
  }

  hideDeleteNewsletterByIdModal = () => {
    this.setState({newsletterId: "", showDeleteModal: false})
  }

  render(){
    let self = this;
    let actionData = {
    columns: [
      { label: 'Sorszám', field: 'weight' },
      { label: 'Email', field: 'email' },
      { label: 'Felírva', field: 'active'},
      { label: 'Törlés', field: 'aktionen', sort: false },
    ],
    rows: this.state.newsletterList.map((row, n) => {
      console.log(row);
      return {
        weight: (
          <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}> {n+1} </div>),
        email: (<div className="d-flex align-items-center justify-content-start" style={{height: "100%"}}> {row.email} </div>),
        
        active: (
          <div className="d-flex align-items-center justify-content-center">
            <MDBSwitch key={n} id="chatfilter" className="form-row" checked={row.handled} onChange={() => this.handleSwitchChange(row.id, row.handled)}
            />
            <span >{row.handler ? "Aktiv" : "Inaktiv"}</span>
        </div>),
        aktionen: (
          <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showDeleteNewsletterByIdModal(row.id)}>
            <MDBIcon fas icon="trash-alt" color="danger"/>
          </MDBBtn>
        ),
      };
    }),
  };
    return(
      <MDBContainer fluid className="p-0">
        <MDBRow className="pt-5 py-3">
          <MDBCol md="6" className="d-flex align-items-center justify-content-start">
          <h1 className='m-0'>Newsletter lista</h1>
          </MDBCol>
        </MDBRow>
        <section>

          <div className='shadow-4 rounded-5 overflow-hidden faq-table'>
            <MDBDatatable
                      hover
                      data={actionData}
                      ofText='összesen'
                      rowsText='Megjelenítés oldalanként'
                      allText='Összes'
                      format={(field) => {
                        if(field==='weight') {
                          return {width: "125px"}
                        }
                        if(field==='active') {
                          return {width: "130px"}
                        }
                        if(field==='aktionen') {
                          return {width: "130px"}
                        }
                      }
                      }
            />
          </div>
        </section>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
      {this.state.showDeleteModal ?
       <DeleteModal hideModal={this.hideDeleteNewsletterByIdModal}
                    showModal={this.state.showDeleteModal}
                    modalTyp={this.state.status}
                    faqId={this.state.newsletterId}
                    onDeleteFAQById={this.onDeleteNewsletterById}
                    modalText={this.state.modalText}
                    /> : false}
      </MDBContainer>
    );
  }

}
export default function NewsletterTableFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <NewsletterTable context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}/>;
}
