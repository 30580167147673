import React, { Component  } from "react";
import ProfileEditForm from '../form-components/ProfileEditForm';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';


class ProfileEditModal extends Component {

	constructor(props) {
    super(props);
    this.state = {
    };
  }



	render() {
    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={this.props.visible}  className="signin-modal profile-edit">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
           
            <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">MY PROFILE</span></MDBModalTitle>
            <ProfileEditForm hideModal={this.props.hideModal} loginEvent={this.props.loginEvent}/>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
  }
}
export default ProfileEditModal;
