import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import video_clock from '../../assets/media/icons/video-clock.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import video_play from '../../assets/media/icons/video-play.png'
import { Fotolist } from '../../data/coverFotok';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput
} from 'mdb-react-ui-kit';


class CategoryModal extends Component {

	constructor(props) {
    super(props);
    this.state = { searchText: ""
    };
  }

  onSearchText  = (event) => {
    this.setState({searchText: event.target.value});
  };
  
  truncateString = (str, num) => {
    if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
  }


	render() {
    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={this.props.visible}  className="category-modal">
      <MDBModalDialog scrollable style={{maxWidth: window.innerWidth/1920 > 1 ? 1760 : "calc(100vw - 200px)"}}>
        <MDBModalContent>
          <MDBModalBody>
            <MDBModalTitle>
              <MDBBtn className='modal-close' onClick={this.props.hideModal}><MDBIcon fas icon="times" size='2x'/></MDBBtn>
              <span className="modal-title">{this.props.categoryName}</span>
            </MDBModalTitle>
              <div>
                <div className="modal-select-search d-flex align-items-center justify-content-center">
                    <MDBRow style={{width: "100%"}} className="m-0 d-flex justify-content-end px-3">
                        <MDBInput label='Example label' id='form1' type='text' value={this.state.searchText} onChange={this.onSearchText} />
                    </MDBRow>
                </div>
                
                <MDBRow  className="m-0">
                            {this.props.videoList.filter(k => (this.props.categoryName !== "Általános" ? k.category === this.props.categoryName : k.category === null)).filter(k => k.name.toLowerCase().includes(this.state.searchText.toLowerCase())).map((k,n) => {
                                    
                                    return(
                                    <MDBCol md="4" key={n} className="pt-5">
                                      <div className="videocard-more" key={n} id="touchsurface">
                                        <div className="background-img">
                                        <img src={k.pictureUrl} alt="thumbnail-image"/>
                                        <div className="description">
                                            <div className="trainer-div">
                                                <img src={Fotolist.content[4].pictureUrl} alt="trainer"/>
                                                <span>{Fotolist.content[4].name}</span>
                                            </div>
                                            <button className="play-button" type="button" onClick={() => { this.props.navigate('/videoplay', { state: { fullUrl: k.fullUrl, categoryName: this.props.categoryName, isTrainerModal: false, trainer: null } })}}><img src={video_play} alt="player"/></button>
                                            <div className="time">
                                                <img src={video_clock} alt="timer"/>
                                                <span>{parseInt(k.duration/60) + "minutes"}</span>
                                            </div>
                                            <div className="bookmark-div">
                                              <div className="more-text">
                                                  <span>{this.truncateString(k.name, 20)}</span>
                                              </div>
                                              <button className="bookmark-button" type="button" onClick={() => this.props.onPutVideoToBookmark(k.id)}>
                                                <img src={bookmark_empty} alt="bookmark" />
                                              </button>
                                            </div>
                                        </div>
                                        </div>
                                      </div>
                                    </MDBCol>
                            )})}
                </MDBRow>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
      
    </MDBModal>
  );
  }
}


function CategoryModalFunc(props) {
  const navigate = useNavigate();

  return <CategoryModal visible={props.visible} 
                        hideModal={props.hideModal} 
                        categoryName={props.categoryName} 
                        videoList={props.videoList} 
                        onPutVideoToBookmark={props.onPutVideoToBookmark}
                        navigate={navigate}
                      />;
}

export default CategoryModalFunc;