// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup,
} from 'mdb-react-ui-kit';




// STYLE IMPORTS
import "react-toggle/style.css";



class ProfileFormTrainer extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: ""
      };
  };

    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const token = this.props.captchaRef.current.getValue();
      if(this.state.password === this.state.confirmPassword){
        if(this.state.email.match(validRegex)){
          this.props.context.interAxios.post('/auth/registertrainer ',
          {
            email: this.state.email,
            password: this.state.password,
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            captchaToken: token,
            }, function(response) {
              self.props.context.closeLoginModal()
              self.props.context.openLoginModal("succesRegistration");
           
                 
            }, function (error) {
              alert('Registration failed! Check the given data!')
          });
      
        }else {
          alert('Email format is incorrect!')
        }
      }else {
        alert('Check the entered passwords!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  {/** FIRST NAME **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="firstName">First name*</label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="lastName">Last name*</label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  {/** EMAIL **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="email">E-mail address*</label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordfirstRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  {/** PASSWORDs **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="password">Password*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordfirstRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.passwordtwoRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="confirmPassword">Re-enter password*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePasswordtwo ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePasswordtwo(!this.props.hidePasswordtwo)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePasswordtwo ? 'password' : 'text'} 
                          value={this.state.confirmPassword}
                          onChange={this.changeHandler}
                          ref={this.props.passwordtwoRef}
                          id='confirmPassword'
                          name='confirmPassword'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.buttonRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>

                <MDBRow className="py-2 m-0">
                  <MDBCol md='12' className="p-0 d-flex justify-content-center align-items-center ">
                    <ReCAPTCHA
                      ref={this.props.captchaRef}
                      sitekey={"6LdU-FAoAAAAALMMm9vjM3lQQJiLLQN5duzkGyP8"}
                    />
                  </MDBCol>
                </MDBRow>
                 <MDBRow center className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                          <div className="d-flex justify-content-center align-items-center pt-3">
                            <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                              <span>Registration</span>
                            </button>
                          </div>
                    </MDBCol>
                  
                  </MDBRow>
                  </div>
              </form>

               
      </>
    );
  }
};

function ProfileFormTrainerFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  const buttonRef = useRef();
  const captchaRef = useRef()
  return <ProfileFormTrainer searchParams={searchParams} 
                      navigate={navigate} 
                      hideModal={props.hideModal} 
                      context={props.context} 
                      firstnameRef={firstnameRef}
                      emailRef={emailRef}
                      passwordfirstRef={passwordfirstRef}
                      passwordtwoRef={passwordtwoRef}
                      buttonRef={buttonRef}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      hidePasswordtwo={hidePasswordtwo}
                      setHidePasswordtwo={setHidePasswordtwo}
                      captchaRef={captchaRef}
                      />;
}

export default ProfileFormTrainerFunc;
