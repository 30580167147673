// react imports
import React, { useState, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import prev from '../../assets/media/icons/prev.png'
import next_button from '../../assets/media/icons/next.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
// CUSTOM COMPONENTS
import { Fotolist } from '../../data/coverFotok';
import { MDBContainer
          } from 'mdb-react-ui-kit';

const CarouselComponentTop = (props) => {
  // REACT ROUTER PARAMS - for processing url
  const cardsRef  = useRef(null);
  // STATES
  const [starttime, setStarttime] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideocardHeight(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setVideocardHeight(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30)
  }, [document.getElementById("thumbnail-image-allo")?.clientWidth]);

  const responsive = {
    desktop2: {
      breakpoint: { max: 4000, min: 1920 },
      items: 5,
      partialVisibilityGutter: 3
    },
    desktop: {
      breakpoint: { max: 1920, min: 1400 },
      items: 4,
      partialVisibilityGutter: 3
    },
    tablet2: {
      breakpoint: { max: 1400, min: 1100 },
      items: 3,
      partialVisibilityGutter: 3
    },
    tablet: {
      breakpoint: { max: 1100, min: 740 },
      items: 3,
      partialVisibilityGutter: 3
    },
    small: {
        breakpoint: { max: 450, min: 0 },
        items: 2,
        partialVisibilityGutter: 1
    }
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
  }

  const swiper = new Swiper('.swiper-top', {
    // Optional parameters
    modules: [FreeMode],
    //slidesPerView: 2,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 640px
      760: {
        slidesPerView: 4,
        spaceBetween: 0
      }
    },
    direction: 'horizontal',
    loop: false,
    effect: 'slide',
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });
  

  const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <MDBContainer style={{maxWidth: videoWidht}} className="button-container">
          <div className="custom-button-group">
            <button className={isFirstStep ? "left-button firststep" : "left-button"} onClick={() => previous()}><img src={prev} alt="prev"/></button>
            <button className="right-button" onClick={() => next()}><img src={next_button} alt="next"/></button>
          </div>
        </MDBContainer>
    );
  };


  return (
    <MDBContainer style={{maxWidth: videoWidht > 1599.99 ? videoWidht-363 : videoWidht > 1080 ? videoWidht-220 : videoWidht-20, padding: 0, position: "relative", height: videocardHeight + 50}} className="top-carousel">
      <div className="d-none d-lg-block">
        <Carousel
            additionalTransfrom={0} 
            swipeable={true}
            draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
            showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
            responsive={responsive}
            infinite={false}
            slidesToSlide={window.matchMedia('(max-device-width: 450px)').matches ? 9 :
                3
            }
            customTransition={window.matchMedia('(max-device-width: 45px)').matches ? "transform 10ms ease-in-out" : "transform 1000ms ease-in-out"}
            transitionDuration={window.matchMedia('(max-device-width: 450px)').matches ? 10 : 1000}
            renderArrowsWhenDisabled={true}
            renderDotsOutside={true}
            centerMode={false}
            keyBoardControl={true}
            containerClass={isFirstStep ? "carousel-container firststep" : "carousel-container"}
            removeArrowOnDeviceType={["small", "tablet"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            afterChange={(previousSlide, { currentSlide, onMove }) => {
                setIsFirstStep(false);
            }}
            renderButtonGroupOutside={true} 
            customButtonGroup={<CustomButtonGroup/>}
            >
            {props.videoList.map((k,n) => {
              let array = k.name.split(" ")
                return(
                    <div className="videocard" key={n} id="touchsurface"  style={{height: videocardHeight}}>
                      <div className="number">
                        {n < 9 ? n+1 : n-8}
                      </div>
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image-allo"/>
                        <div className="description" style={{justifyContent: k.overlayTextPositionId === 1 ? "flex-start" : "flex-end", 
                                     alignItems: k.overlayTextPositionId === 2 ? "flex-start" : "flex-end"
                                    }}>
                            <span className="description-text"
                              style={{textAlign: k.overlayTextPositionId === 1 ? "start" : "end", 
                                  fontFamily: k.overlayTextFontId === 1 ? "Impact" : k.overlayTextFontId === 2 ? "Dreams" : "din-condensed", 
                                  color: k.overlayTextColorId === 1 ? "#55FFDA" : k.overlayTextColorId === 2 ? "#FF557B" : "#FFFFFF",
                                  opacity: k.overlayTextColorId === 3 ? .8 : 1,
                                  lineHeight: k.overlayTextFontId === 1 ? "100%" : k.overlayTextFontId === 2 ? "22px" : "100%",
                                  fontSize: k.overlayTextFontId === 2 ? "4px" : k.overlayTextFontId === 1 ? "19px" : "22px",
                                  textTransform: "uppercase"
                                }}>
                          {array.map((l,m) => {
                            return(
                                <span style={{
                                  fontSize: k.overlayTextFontId === 2 ? l.split("").length > 10 ? 5*10/l.split("").length + "px" : "5px" :
                                  k.overlayTextFontId === 1 ? l.split("").length > 19 ? 24*19/l.split("").length + "px" : "24px" :
                                  l.split("").length > 19 ? 2819/l.split("").length + "px" : "28px"}} id="test">
                                  {l + " "}
                                </span>
                                      
                                    
                          )})}
                            </span>
                        </div>
                        <div style={{position: "absolute", width: "100%", height: "40%",
                                     background:  "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 24%, rgba(0,0,0,0.24) 45.11%, #000000 100%)",
                                     transform: k.overlayTextPositionId === 2 ? "scale(-1)" : "unset",
                                     bottom: k.overlayTextPositionId === 1 ? "-5px" : "unset",
                                     top: k.overlayTextPositionId === 2 ? "-5px" : "unset",
                                    }}/>
                        <div className="description-hover">
                          <div className="trainer-div">
                              <img src={props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.profileImage?.thumbnailUrl} alt="trainer"/>
                              <span>{props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.firstName + " " + props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.lastName}</span>
                          </div>
                            <button  className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: props.trainerList.filter(l => l.trainerTag === k.trainerTag) } })}}><img src={video_play} alt="player"/></button>
                            <div className="time">
                                <img src={video_clock} alt="timer"/>
                                <span>{parseInt(k.duration/60) + "minutes"}</span>
                            </div>
                            <div className="bookmark-div">
                            <div className="more-text">
                                <span>{truncateString(k.name, 40)}</span>
                            </div>
                            <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                              () => props.onDeleteVideoFromBookmark(k.id) : () => props.onPutVideoToBookmark(k.id)}>
                              <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                           </button> 
                          </div>
                        </div>
                      </div>
                    </div>
            )})}
        </Carousel>
      </div>
      <div className="swiper-top d-block d-lg-none">
        <div className="swiper-wrapper">
          {props.videoList.map((k,n) => {
            return(
              <>
              {starttime && (
                <div class="swiper-slide" key={n}>
                <div className="videocard-respo">
                <div className="number">
                  <span>
                    {n < 9 ? n+1 : n-8}
                  </span>
                  
                </div>
                <div className="background-img" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: props.trainerList.filter(l => l.trainerTag === k.trainerTag) } })}}>
                  <img src={k.pictureUrl} alt="thumbnail-image"/>
                  <div className="more-text">
                      <span>{truncateString(k.name, 26)}</span>
                  </div>
                </div>
              </div>
            </div>
              )}
              </>
          )})}
        </div>
      </div>
      
    </MDBContainer>
  );
};

export default CarouselComponentTop;
