// REACT IMPORTS
import React, { useState, useEffect } from "react";



// IMPORT MODALCOMPONENT
import CreateImageModal from '../modals/ImageUploadWithCrop/CreateImageModal.js'


const SingleImageUploader = (props) => {
  // STATES
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showCreateImageModal, setShowCreateImageModal] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [isRenderTrue, setIsRenderTrue] = useState(false);

  // MAIN FUNC
  const addImage = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setSelectedImageUrl(imageUrl);
    props.setNewImageUrl(file)

  };
  const removeImage = () => {
    setSelectedImageUrl(null);
    props.setNewImageUrl(null)
  };
  const preventAndStop = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // EVENTS
  const onSelectImage = (event) => {
    //const selectedFiles = event.target.files;

    if (!event && event.length !== 1) {
      return;
    }

    addImage(event);

  };
  const dropOnImagesArea = (e) => {
    preventAndStop(e);

    addImage(e.dataTransfer.files);
  };

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e)
      setShowCreateImageModal(true)
      /*const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({crop: undefined})*/
    }
  };


  // on load
  useEffect(() => {
    if (props.imageUrl) {
      console.log(props.imageUrl)
      setSelectedImageUrl(props.imageUrl);
      
    }
  }, []);

  return (
    <div className={"single-image-uploader"}>
      
        <div
        className={`images-area`  + (selectedImageUrl ? "" : " center")}
        onDragEnter={preventAndStop}
        onDragOver={preventAndStop}
        onDrop={dropOnImagesArea}
        >
        {selectedImageUrl && selectedImageUrl !== "" &&(
          <div
            className="uploaded-item"
          >
            <div className="image-icons">
              <div className="image-icon" style={{backgroundColor: 'white'}}>
                <i
                  className="fa-regular fa-trash-alt"
                  onClick={removeImage}
                ></i>
              </div>
            </div>

            <img src={selectedImageUrl} alt="preview image" />
          </div>
        )}

        <label
              className='upload-button'
              htmlFor="new-files"
            >
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={onSelectFile}
                id="new-files"
              />

                  {props.uploadBtnMessage}
            </label>
        </div>
     
     
      {showCreateImageModal && imageFile ?
      <CreateImageModal onSelectImage={onSelectImage} uploadBtnMessage={props.uploadBtnMessage} showCreateImageModal={showCreateImageModal} setShowCreateImageModal={setShowCreateImageModal} imageFile={imageFile}/>:false}
    </div>
  );
};

export default SingleImageUploader;
