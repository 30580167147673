// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup
} from 'mdb-react-ui-kit';

// STYLE IMPORTS
import "react-toggle/style.css";


class SignInForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    password: ""      
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      let customer = {
                    email: this.state.email,
                    password: this.state.password    
      };
      this.props.loginEvent(this.state.email, this.state.password)
      //this.props.context.onSignIn()
      //this.props.hideModal();
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

  
  render() {
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="signin-form" autoComplete="off">
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="firstName">E-mail</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordRef.current.focus()
                          }
                        }}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Password</label><br/>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.buttonRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>


                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="p-0" >
                          <div className="d-flex align-items-center justify-content-center">
                            <button type="button" className="rergistration-button modal-button text-button" 
                              onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("forgotpassword");}}>
                            <span>FORGOT PASSWORD</span>
                            </button>
                          </div>
                          <div className="d-flex align-items-center justify-content-between" > 
                            <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                            <span>SIGN IN</span>
                            </button>
                            <button type="button" className="rergistration-button modal-button  black" 
                            onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("signup");}}>
                            <span>JOIN US</span>
                            </button>
                          </div>
                          
                    </MDBCol>
                  </MDBRow>

                </div>
              </form>
      </>
    );
  }
};

function SignInFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const passwordRef = useRef();
  const buttonRef = useRef();
  const [hidePassword, setHidePassword] = useState(true);

  return <SignInForm searchParams={searchParams} 
                     navigate={navigate} 
                     hideModal={props.hideModal} 
                     context={context} 
                     loginEvent={props.loginEvent} 
                     passwordRef={passwordRef}
                     buttonRef={buttonRef}
                     hidePassword={hidePassword}
                     setHidePassword={setHidePassword}
                     />;
}

export default SignInFormFunc;
