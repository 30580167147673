import React, { Component, useContext, useEffect, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext'
import SideNav from './NavbarComponent/SideNav.js'
import Navbar from './NavbarComponent/Navbar.js'



import {MDBContainer, MDBRow, MDBCol
} from 'mdb-react-ui-kit';




class AdminLandingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
       }
  };

  

    render() {
        return(
          <MDBContainer fluid>
          <MDBRow className='justify-content-center'>
            <MDBCol md='12' className="p-0">
              <header style={{ position: 'relative', overflow: 'hidden', minHeight: '100vh'}}>
                <SideNav isSideNavOpen={true}/>
                <Navbar/>
                <Outlet/>
              </header>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        )
    }
}

export default function LandingPageFunc() {
  const navigate = useNavigate();
  if(window.matchMedia('(max-device-width: 768px)').matches){
    return <></>;
  }else{
    const context = useContext(AppContext);
    const [showBasic, setShowBasic] = useState(false);
    const [showBasicSubmenu, setShowBasicSubmenu] = useState(false);
    useEffect(() => {
      if (!context.isLoggedIn()) {
        navigate('/admin/login');
      }
    });
    return <AdminLandingPage  context={context}
                              showBasic={showBasic}
                              setShowBasic={setShowBasic}
                              showBasicSubmenu={showBasicSubmenu}
                              setShowBasicSubmenu={setShowBasicSubmenu}/>;
  }

}
