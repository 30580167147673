import React, { useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';

// LAYOUT, PAGE IMPORTS
import Layout from './components/layout/Layout';
import VideosPage from './components/pages/VideosPage';
import LandingPage from './components/pages/LandingPage';
import KapcsolatokPage from './components/pages/KapcsolatokPage';
import AdminLoginPage from './components/pages/admin/AdminLoginPage';
import AdminLandingPage from './components/pages/admin/AdminLandingPage';
import FaqPage from './components/pages/admin/FaqPage';
import NewsletterPage from './components/pages/admin/NewsletterPage';
import ClientPage from './components/pages/admin/ClientPage';
import LiveCalendarPage from './components/pages/admin/CalendarComponent/LiveCalendarPage';
import ImageUploadPage from './components/pages/admin/ImageUploadPage';
import VideosDetailPage from './components/pages/VideosDetailPage';
import TrainerPage from './components/pages/TrainerPage';
import TrainerPageDetail from './components/pages/TrainerPageDetail';
import CategoryDetailPage from './components/pages/CategoryDetailPage';
import VideoPlayPage from './components/pages/VideoPlayPage';

function App() {
  const [todos, setTodos] = useState(false);

  return (
    <AppContextProvider>
      <BrowserRouter>
       
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route path="" element={<LandingPage />} />
              <Route path="/home" element={<LandingPage />} />
              <Route path="/profile/change-password" element={<LandingPage />} />
              <Route path="/kapcsolatok" element={<KapcsolatokPage />} />
              <Route path="/videos" element={<VideosPage isPageRender={todos}/>} />
              <Route path="/videoplay" element={<VideoPlayPage />} />
              <Route path="/videodetail" element={<VideosDetailPage />} />
              <Route path="/categorydetail" element={<CategoryDetailPage />} />
              <Route path="/trainer" element={<TrainerPage />} />
              <Route path="/trainerdetail" element={<TrainerPageDetail />} />
            </Route>
              <Route path="/admin" element={<AdminLandingPage/>}>
              <Route path="faq/*" element={<FaqPage/>}/>
              <Route path="newsletter/*" element={<NewsletterPage/>}/>
              <Route path="client/*" element={<ClientPage/>}/>
              <Route path="livecalendar/*" element={<LiveCalendarPage/>}/>
              <Route path="imageupload/*" element={<ImageUploadPage/>}/>
            </Route>

            <Route path="admin/login" element={<AdminLoginPage/>}/>
          </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
