// react imports
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import first_image from '../../assets/media/images/Group 5.png'
import second_image from '../../assets/media/images/Group 4.png'
import third_image from '../../assets/media/images/Group 8.png'
import video_play from '../../assets/media/icons/video-play.png'


// CUSTOM COMPONENTS
import AppContext from '../../AppContext';


import { MDBContainer
          } from 'mdb-react-ui-kit';
import Gyik from '../common/Gyik'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const LandingPage = (props) => {

  const context = useContext(AppContext);
  
  // react router navigation
  const navigate = useNavigate();

  // STATES
  const [improveVideoWidth, setImproveVideoWidth] = useState(window.innerWidth < 1372 ? window.innerWidth : 1372);
  const [improveVideoHeight, setimproveVideoHeight] = useState(window.innerWidth < 1372 ? window.innerWidth*9/16 : 771.76);
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [isPlayVideoTwo, setIsPlayVideoTwo] = useState(false);
  const [isPlayVideoThree, setIsPlayVideoThree] = useState(false);
  const [isPlayVideoFour, setIsPlayVideoFour] = useState(false);

  const [threeImagesHeight, setThreeImagesHeight] = useState(window.innerWidth > 1920 ?  2812 : (window.innerWidth/1920)*2812);
  const [firstImageWidth, setFirstImageWidth] = useState(window.innerWidth > 1920 ?  1143 : (window.innerWidth/1920)*1143);
  const [secondImageWidth, setSecondImageWidth] = useState(window.innerWidth > 1920 ?  1154.22 : (window.innerWidth/1920)*1154.22);
  const [thirdImageWidth, setThirdImageWidth] = useState(window.innerWidth > 1920 ?  1316.5 : (window.innerWidth/1920)*1316.5);

  const [firstTop, setFirstTop] = useState(window.innerWidth > 1920 ?  201.5 : (window.innerWidth/1920)*201.5);
  const [secondTop, setSecondTop] = useState(window.innerWidth > 1920 ?  1945 : (window.innerWidth/1920)*1945);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [isVideoHoverTwo, setIsVideoHoverTwo,] = useState(false);
  const [isVideoHoverThree, setIsVideoHoverThree] = useState(false);
  const [isVideoHoverFour, setIsVideoHoverFour] = useState(false);
   

  const handleWindowSizeChange = () => {
    setImproveVideoWidth(window.innerWidth < 1372 ? window.innerWidth : 1372);
    setimproveVideoHeight(window.innerWidth < 1372 ? window.innerWidth*0.563 : 771.767)
    setThreeImagesHeight(window.innerWidth > 1920 ?  2812 : (window.innerWidth/1920)*2812);
    setFirstImageWidth(window.innerWidth > 1920 ?  1143 : (window.innerWidth/1920)*1143);
    setSecondImageWidth(window.innerWidth > 1920 ?  1154.22 : (window.innerWidth/1920)*1154.22);
    setThirdImageWidth(window.innerWidth > 1920 ?  1316.5 : (window.innerWidth/1920)*1316.);
    setFirstTop(window.innerWidth > 1920 ?  201.5 : (window.innerWidth/1920)*201.5);
    setSecondTop(window.innerWidth > 1920 ?  1945 : (window.innerWidth/1920)*1945);
  };

  const scrollEventListener = () => {
    /*if (window.innerWidth > 991.98){
        if(window.scrollY > document.getElementById('first-video').getBoundingClientRect().top + window.pageYOffset-980 && window.scrollY < document.getElementById('second-video').getBoundingClientRect().top + window.pageYOffset - 680){
            setIsPlayVideo(true); 
            setIsPlayVideoTwo(false); 
            setIsPlayVideoThree(false); 
            setIsPlayVideoFour(false);
            setTimeout(() => {
                setIsVideoHover(true);
                setIsVideoHoverTwo(false);
                setIsVideoHoverThree(false);
                setIsVideoHoverFour(false)}, 500)
        }
        if(window.scrollY > document.getElementById('second-video').getBoundingClientRect().top + window.pageYOffset -680 && window.scrollY < document.getElementById('third-video').getBoundingClientRect().top + window.pageYOffset -1680){
            setIsPlayVideoTwo(true); 
            setIsPlayVideo(false);
            setIsPlayVideoThree(false); 
            setIsPlayVideoFour(false)
            setTimeout(() => {
                setIsVideoHover(false);
                setIsVideoHoverTwo(true);
                setIsVideoHoverThree(false);
                setIsVideoHoverFour(false)}, 500)
        }
    
        if(window.scrollY > document.getElementById('third-video').getBoundingClientRect().top + window.pageYOffset -850 && window.scrollY < document.getElementById('fourth-video').getBoundingClientRect().top + window.pageYOffset -740){
            setIsPlayVideoThree(true); 
            setIsPlayVideo(false);
            setIsPlayVideoTwo(false); 
            setIsPlayVideoFour(false);
            setTimeout(() => {
                setIsVideoHover(false);
                setIsVideoHoverTwo(false);
                setIsVideoHoverThree(true);
                setIsVideoHoverFour(false)}, 500)
        }
    
        if(window.scrollY > document.getElementById('fourth-video').getBoundingClientRect().top + window.pageYOffset -780 && window.scrollY < document.getElementById('fourth-video').getBoundingClientRect().top + window.pageYOffset -500){
            setIsPlayVideoFour(true); 
            setIsPlayVideo(false);
            setIsPlayVideoTwo(false);
            setIsPlayVideoThree(false);
            setTimeout(() => {
                setIsVideoHover(false);
                setIsVideoHoverTwo(false);
                setIsVideoHoverThree(false);
                setIsVideoHoverFour(true)}, 500)
        }
        if(window.scrollY > document.getElementById('fourth-video').getBoundingClientRect().top + window.pageYOffset +180){
            setIsPlayVideoFour(false); 
            setIsPlayVideo(false); 
            setIsPlayVideoTwo(false); 
            setIsPlayVideoThree(false);
            setTimeout(() => {
                setIsVideoHover(false);
                setIsVideoHoverTwo(false);
                setIsVideoHoverThree(false);
                setIsVideoHoverFour(false)}, 500)
        }
    }*/
    
    setTimeout(() => {
        if(window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videos"){
           /* if(window.scrollY < 100){
                context.onSetActiveItem("1");
                setIsPlayVideo(false); 
                setIsPlayVideoTwo(false); 
                setIsPlayVideoThree(false); 
                setIsPlayVideoFour(false);
                setTimeout(() => {
                    setIsVideoHover(false);
                    setIsVideoHoverTwo(false);
                    setIsVideoHoverThree(false);
                    setIsVideoHoverFour(false)}, 500)
                
            }*/
            if(window.scrollY > document.getElementById('videos').getBoundingClientRect().top + window.pageYOffset -400){
                //context.onSetActiveItem("2");
            }
            if(window.scrollY > document.getElementById('faq').getBoundingClientRect().top + window.pageYOffset -400){
                context.onSetActiveItem("3");
            }
            if (window.innerWidth > 991.98){
                if(window.scrollY > document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset -1000){
                    context.onSetActiveItem("4");
                }
            }else{
                if(window.scrollY > document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset -700){
                    context.onSetActiveItem("4");
                }
            }
            
           
        }
       
      }, 600)
    
   
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        document.removeEventListener("scroll", scrollEventListener);
    };
    
  }, []);
  
 
  return (
    <MDBContainer style={{maxWidth: "1920px"}} className="landing-page">
        <section className="signin-section" id="signin-section"  style={{backgroundSize: improveVideoWidth, height: improveVideoHeight}}>
            <div className="title-text">
                <span style={{fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*120}}>PERSONAL 
                <span style={{color: "#FF557B", fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*120}}>TRAINING</span> DONE BY EXPERTS.</span>
            </div>
                <div className="buttons">
                {!context.isLoggedIn() && (<>
                <button className="sign-in-button" onClick={() => context.openLoginModal("signin")} style={{width: window.innerWidth/1920 > 1 ? 341 : (window.innerWidth/1920)*341, 
                                                                                                            height:  window.innerWidth/1920 > 1 ? 117 : (window.innerWidth/1920)*117}}>
                    <span>SIGN IN</span>
                </button>
                <button className="sign-up-button" onClick={() => context.openLoginModal("signup")} style={{width: window.innerWidth/1920 > 1 ? 341 : (window.innerWidth/1920)*341, 
                                                                                                            height:  window.innerWidth/1920 > 1 ? 117 : (window.innerWidth/1920)*117}}>
                    <p>JOIN US</p>
                    <span>FROM 10 EUR / MONTH</span>
                </button>
                </>)}
                </div>
            
           
        </section>
        <section className="imporve-section" id="imporve-section"  style={{backgroundSize: improveVideoWidth, height: improveVideoHeight}}>
            <div className="improve-text d-none d-lg-block">
                <span>IMPROVE YOUR <span style={{color: "#FF557B"}}>LIFESTYLE</span></span>
            </div>
           
            <div className="right-line"/>
            <div className="iframe-div" style={{width: improveVideoWidth, height: improveVideoHeight, overflow: "hidden", cursor: "pointer"}} onClick={() => {setIsPlayVideo(!isPlayVideo); 
                    setIsPlayVideoTwo(false); 
                    setIsPlayVideoThree(false); 
                    setIsPlayVideoFour(false);
                    setIsVideoHoverTwo(false);
                    setIsVideoHoverThree(false);
                    setIsVideoHoverFour(false);
                    setTimeout(() => {
                        setIsVideoHover(!isVideoHover)}, 200)
                    }}>
                <div className="improve-text-respo" style={{maxWidth: window.innerWidth/1920 > 1 ? 470 : (window.innerWidth/1920)*500, top: window.innerWidth/1920 > 1 ? 50 : (window.innerWidth/1920)*50}}>
                    <span style={{fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*120}}>IMPROVE YOUR <span style={{color: "#FF557B",
                fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*120}}>LIFESTYLE</span></span>
                </div>
                {window.innerWidth < 992 ? 
                <button id="first-video" className="play-button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140, minWidth: "40px"}} type="button"
                onClick={() => {setIsPlayVideo(!isPlayVideo); 
                    setIsPlayVideoTwo(false); 
                    setIsPlayVideoThree(false); 
                    setIsPlayVideoFour(false);
                    setIsVideoHoverTwo(false);
                    setIsVideoHoverThree(false);
                    setIsVideoHoverFour(false);
                    setTimeout(() => {
                        setIsVideoHover(!isVideoHover)}, 200)
                    }}>
                    <img src={video_play} alt="play"/>
                </button>:
                <button id="first-video" className="play-button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140}} type="button"></button>}
                {isPlayVideo && (
                    <div className={isVideoHover ? "iframe-player hover-effect" : "iframe-player"} style={{cursor: "pointer"}}>
                        <iframe src="https://player.vimeo.com/video/441001905?h=821ceeda90&autoplay=1&controls=0&loop=1"  width={improveVideoWidth} height={improveVideoHeight} allow="autoplay"> </iframe>
                        <div style={{position: "absolute", top: 0, left: 0, width: window.innerWidth < 1372 ? window.innerWidth : 1372, height: window.innerWidth < 1372 ? window.innerWidth*9/16 : 771.76}} onClick={() => {setIsPlayVideo(false); 
                        setIsPlayVideoTwo(false); 
                        setIsPlayVideoThree(false); 
                        setIsPlayVideoFour(false);
                        setIsVideoHoverTwo(false);
                        setIsVideoHoverThree(false);
                        setIsVideoHoverFour(false);
                        }}></div>
                    </div>  
                    
                )}
            </div>
        </section>
        <section className="all-videos-section" id="videos" style={{overflow: "hidden"}}>
            <div className="trhee-images-div" style={{height: threeImagesHeight}}>
                <div className="first-image" style={{width: firstImageWidth, cursor: "pointer"}} onClick={() => {setIsPlayVideo(false); 
                            setIsPlayVideoTwo(!isPlayVideoTwo); 
                            setIsPlayVideoThree(false); 
                            setIsPlayVideoFour(false);
                            setIsVideoHover(false);
                            setIsVideoHoverThree(false);
                            setIsVideoHoverFour(false);
                            setTimeout(() => {
                                setIsVideoHoverTwo(!isVideoHoverTwo)}, 300)
                            }}>
                    {!isVideoHoverTwo && (<>
                        <img src={first_image} alt="first-image" style={{zIndex: isPlayVideoThree ? 1500 : 1000}}/>
                    
                        <div className="title-text d-none d-lg-block" style={{width: window.innerWidth/1920 > 1 ? 378 : (window.innerWidth/1920)*378, height: window.innerWidth/1920 > 1 ? 115 : (window.innerWidth/1920)*115,
                            bottom: window.innerWidth/1920 > 1 ? 620 : (window.innerWidth/1920)*620 }}>
                            <span style={{fontSize: window.innerWidth/1920 > 1 ? 28 : (window.innerWidth/1920)*30}}>Kiss Patrik</span>
                        </div>
                    </>)}
                    
                    {window.innerWidth < 992 ? 
                        <button id="second-video" className="play-button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140, minWidth: "40px",
                            left:  window.innerWidth/1920 > 1 ? 860 : (window.innerWidth/1920)*860,
                            top: window.innerWidth/1920 > 1 ? 800 : (window.innerWidth/1920)*800}} type="button"
                        onClick={() => {setIsPlayVideo(false); 
                            setIsPlayVideoTwo(!isPlayVideoTwo); 
                            setIsPlayVideoThree(false); 
                            setIsPlayVideoFour(false);
                            setIsVideoHover(false);
                            setIsVideoHoverThree(false);
                            setIsVideoHoverFour(false);
                            setTimeout(() => {
                                setIsVideoHoverTwo(!isVideoHoverTwo)}, 300)
                            }}>
                            <img src={video_play} alt="play"/>
                        </button>:
                        <button id="second-video" className="play-button" type="button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140,
                                left:  window.innerWidth/1920 > 1 ? 25 : (window.innerWidth/1920)*25,
                                top: window.innerWidth/1920 > 1 ? 35 : (window.innerWidth/1920)*35 }}
                                >
                        </button>}
                    {isPlayVideoTwo && (<>
                        <div className="mask-first" style={{left: window.innerWidth/1920 > 1 ? -900 : (window.innerWidth/1920)*(-900),
                            width: window.innerWidth/1920 > 1 ? 1666 : (window.innerWidth/1920)*1666,
                            height: window.innerWidth/1920 > 1 ? 1186 : (window.innerWidth/1920)*1186, borderBottomWidth: window.innerWidth/1920 > 1 ? 124 : (window.innerWidth/1920)*124,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 93 : (window.innerWidth/1920)*93, borderLeftWidth: window.innerWidth/1920 > 1 ? 900 : (window.innerWidth/1920)*900}}/>
                        
                        <div className="mask-first-2" style={{left: window.innerWidth/1920 > 1 ? -900 : (window.innerWidth/1920)*(-900), bottom: window.innerWidth/1920 > 1 ? -1820 : (window.innerWidth/1920)*(-1820),
                            width: window.innerWidth/1920 > 1 ? 1666 : (window.innerWidth/1920)*1666,
                            height: window.innerWidth/1920 > 1 ? 635 : (window.innerWidth/1920)*635,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 93 : (window.innerWidth/1920)*93, borderLeftWidth: window.innerWidth/1920 > 1 ? 1195 : (window.innerWidth/1920)*1195}}/>
                        
                        <div className="mask-first-3" style={{right: window.innerWidth/1920 > 1 ? -3000 : (window.innerWidth/1920)*(-3000), width: window.innerWidth/1920 > 1 ? 3377 : (window.innerWidth/1920)*3377,
                            height: window.innerWidth/1920 > 1 ? 1820 : (window.innerWidth/1920)*1820, borderBottomWidth: window.innerWidth/1920 > 1 ? 617 : (window.innerWidth/1920)*617,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 3000 : (window.innerWidth/1920)*3000, borderTopWidth: window.innerWidth/1920 > 1 ? 565 : (window.innerWidth/1920)*565}}/>
                        
                        <div className={isVideoHoverTwo ? "video-div hover-effect" : "video-div"} style={{left: window.innerWidth/1920 > 1 ? -1600 : (window.innerWidth/1920)*(-1600)}}>
                            <iframe src="https://player.vimeo.com/video/325878883?h=9e4566596c&autoplay=1#t=0m4s&controls=0&loop=1"  height={window.innerWidth/1920 > 1 ? 1800 : (window.innerWidth/1920)*1800} width={window.innerWidth/1920 > 1 ? 
                            4500 : (window.innerWidth/1920)*4500} allow="autoplay"></iframe>
                        </div>
                    </>)}
                </div>
                
                <div className="second-image" style={{width: secondImageWidth, top: firstTop, cursor: "pointer"}} onClick={() => {setIsPlayVideo(false); 
                            setIsPlayVideoTwo(false); 
                            setIsPlayVideoThree(!isPlayVideoThree); 
                            setIsPlayVideoFour(false);
                            setIsVideoHover(false);
                            setIsVideoHoverTwo(false);
                            setIsVideoHoverFour(false);
                            setTimeout(() => {
                                setIsVideoHoverThree(!isVideoHoverThree)}, 400)}}>
                    {!isVideoHoverThree && (<>
                        <img src={second_image} alt="second-image"  style={{zIndex: isPlayVideoTwo ? 1500 : 1000}}/>
                        <div className="title-text d-none d-lg-block" style={{width: window.innerWidth/1920 > 1 ? 378 : (window.innerWidth/1920)*378, height: window.innerWidth/1920 > 1 ? 115 : (window.innerWidth/1920)*115,
                            bottom: window.innerWidth/1920 > 1 ? 140 : (window.innerWidth/1920)*140 }}>
                            <span style={{fontSize: window.innerWidth/1920 > 1 ? 28 : (window.innerWidth/1920)*30}}>Bob Legend</span>
                        </div>
                    </>)}

                    {window.innerWidth < 992 ? 
                        <button id="third-video" className="play-button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140, minWidth: "40px",
                        left:  window.innerWidth/1920 > 1 ? 100 : (window.innerWidth/1920)*100,
                        top: window.innerWidth/1920 > 1 ? 1393 : (window.innerWidth/1920)*1393 }} type="button"
                        onClick={() => {setIsPlayVideo(false); 
                            setIsPlayVideoTwo(false); 
                            setIsPlayVideoThree(!isPlayVideoThree); 
                            setIsPlayVideoFour(false);
                            setIsVideoHover(false);
                            setIsVideoHoverTwo(false);
                            setIsVideoHoverFour(false);
                            setTimeout(() => {
                                setIsVideoHoverThree(!isVideoHoverThree)}, 400)}}>
                            <img src={video_play} alt="play"/>
                        </button>:
                        <button id="third-video" className="play-button" type="button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140,
                                left:  window.innerWidth/1920 > 1 ? 131 : (window.innerWidth/1920)*131,
                                top: window.innerWidth/1920 > 1 ? 1393 : (window.innerWidth/1920)*1393 }}
                                >
                        </button>}
                    {isPlayVideoThree && (<>
                        <div className="mask-first" style={{right: window.innerWidth/1920 > 1 ? -900 : (window.innerWidth/1920)*(-900), top: window.innerWidth/1920 > 1 ? -3 : (window.innerWidth/1920)*(-3),
                            width: window.innerWidth/1920 > 1 ? 1677 : (window.innerWidth/1920)*1677,
                            height: window.innerWidth/1920 > 1 ? 1281 : (window.innerWidth/1920)*1281,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 900 : (window.innerWidth/1920)*900, borderLeftWidth: window.innerWidth/1920 > 1 ? 93 : (window.innerWidth/1920)*93,
                            borderBottomWidth: window.innerWidth/1920 > 1 ? 124 : (window.innerWidth/1920)*124}}/>
                        
                        <div className="mask-first-2" style={{left: window.innerWidth/1920 > 1 ? -1550 : (window.innerWidth/1920)*(-1550), bottom: window.innerWidth/1920 > 1 ? -1916.53 : (window.innerWidth/1920)*(-1916.53),
                            width: window.innerWidth/1920 > 1 ? 1927 : (window.innerWidth/1920)*1927,
                            height: window.innerWidth/1920 > 1 ? 1920 : (window.innerWidth/1920)*1920,
                            borderBottomWidth: window.innerWidth/1920 > 1 ? 142 : (window.innerWidth/1920)*142, borderLeftWidth: window.innerWidth/1920 > 1 ? 1549 : (window.innerWidth/1920)*1549, 
                            borderTopWidth: window.innerWidth/1920 > 1 ? 1141 : (window.innerWidth/1920)*1141}}/>
                        
                        <div className="mask-first-3" style={{right: window.innerWidth/1920 > 1 ? -900 : (window.innerWidth/1920)*(-900), bottom: window.innerWidth/1920 > 1 ? -1916.53 : (window.innerWidth/1920)*(-1916.53), 
                            width: window.innerWidth/1920 > 1 ? 1677 : (window.innerWidth/1920)*1677,
                            height: window.innerWidth/1920 > 1 ? 639 : (window.innerWidth/1920)*639, borderLeftWidth: window.innerWidth/1920 > 1 ? 93 : (window.innerWidth/1920)*93,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 1208 : (window.innerWidth/1920)*1208}}/>
                        
                        <div className={isVideoHoverThree ? "video-div hover-effect" : "video-div"} style={{left: window.innerWidth/1920 > 1 ? -1500 : (window.innerWidth/1920)*(-1500), top: window.innerWidth/1920 > 1 ? -302 : (window.innerWidth/1920)*(-302)}}>
                            <iframe src="https://player.vimeo.com/video/343985779?h=4d34885973&autoplay=1#t=0m4s&controls=0&loop=1"  height={window.innerWidth/1920 > 1 ? 2518 : (window.innerWidth/1920)*2518} width={window.innerWidth/1920 > 1 ? 
                            4500 : (window.innerWidth/1920)*4500} allow="autoplay"></iframe>
                        </div>
                    </>)}
                </div>
                
                <div className="third-image" style={{width: thirdImageWidth, top: secondTop, cursor: "pointer"}} onClick={() => {setIsPlayVideo(false); 
                        setIsPlayVideoTwo(false); 
                        setIsPlayVideoThree(false); 
                        setIsPlayVideoFour(!isPlayVideoFour);
                        setIsVideoHover(false);
                        setIsVideoHoverTwo(false);
                        setIsVideoHoverThree(false);
                        setTimeout(() => {
                            setIsVideoHoverFour(!isVideoHoverFour)}, 300)}}>
                {!isVideoHoverFour && (<>
                    <img src={third_image} alt="third-image"  style={{zIndex: isPlayVideoThree ? 1500 : 1000}}/>
                    
                    <div className="title-text d-none d-lg-block" style={{width: window.innerWidth/1920 > 1 ? 378 : (window.innerWidth/1920)*378, height: window.innerWidth/1920 > 1 ? 115 : (window.innerWidth/1920)*115}}>
                        <span style={{fontSize: window.innerWidth/1920 > 1 ? 28 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*30 : (window.innerWidth/1920)*40}}>The Gym</span>
                    </div>
                </>)}
                {window.innerWidth < 992 ? 
                    <button id="fourth-video" className="play-button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140, minWidth: "40px",
                    top: window.innerWidth/1920 > 1 ? 450 : (window.innerWidth/1920)*450  }} type="button"
                    onClick={() => {setIsPlayVideo(false); 
                        setIsPlayVideoTwo(false); 
                        setIsPlayVideoThree(false); 
                        setIsPlayVideoFour(!isPlayVideoFour);
                        setIsVideoHover(false);
                        setIsVideoHoverTwo(false);
                        setIsVideoHoverThree(false);
                        setTimeout(() => {
                            setIsVideoHoverFour(!isVideoHoverFour)}, 300)}}>
                        <img src={video_play} alt="play"/>
                    </button>:
                    <button id="fourth-video" className="play-button" type="button" style={{width: window.innerWidth/1920 > 1 ? 111 : (window.innerWidth/1920)*140,
                        top: window.innerWidth/1920 > 1 ? 387.75 : (window.innerWidth/1920)*387.75  }}
                            >
                    </button>}
                {isPlayVideoFour && (<>
                    <div className="mask-first-2" style={{left: window.innerWidth/1920 > 1 ? 376 : (window.innerWidth/1920)*(376),
                            width: window.innerWidth/1920 > 1 ? 564 : (window.innerWidth/1920)*564,
                            height: window.innerWidth/1920 > 1 ? 775.5 : (window.innerWidth/1920)*775.5,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 87 : (window.innerWidth/1920)*87, borderLeftWidth: window.innerWidth/1920 > 1 ? 99 : (window.innerWidth/1920)*99, 
                            borderTopWidth: window.innerWidth/1920 > 1 ? 141 : (window.innerWidth/1920)*141}}/>
                    
                   <div className="mask-first-3"  style={{right: window.innerWidth/1920 > 1 ? -100 : (window.innerWidth/1920)*(-100),
                            width: window.innerWidth/1920 > 1 ? 480 : (window.innerWidth/1920)*480,
                            height: window.innerWidth/1920 > 1 ? 775.5 : (window.innerWidth/1920)*775.5,
                            borderRightWidth: window.innerWidth/1920 > 1 ? 100 : (window.innerWidth/1920)*100, 
                            borderTopWidth: window.innerWidth/1920 > 1 ? 264 : (window.innerWidth/1920)*264}}/>

                    <div className={isVideoHoverFour ? "video-div hover-effect" : "video-div"} >
                        <iframe src="https://player.vimeo.com/video/327527771?h=d48315075f&autoplay=1&controls=0&loop=1"  height={window.innerWidth/1920 > 1 ? 775.5 : (window.innerWidth/1920)*775.5} width={window.innerWidth/1920 > 1 ? 
                        1379 : (window.innerWidth/1920)*1379} allow="autoplay"></iframe>
                </div>
                </>)}
                </div>
                
                <div className="videos-title"  style={{width: window.innerWidth/1920 > 1 ? 470 : (window.innerWidth/1920)*470, height: window.innerWidth/1920 > 1 ? 510.5 : (window.innerWidth/1920)*510.5}}>
                    <span style={{fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*140}}>120 + Training Videos</span>
                </div>
                <div className="videos-description">
                    <span>Get ready for an energy boost from our professional trainers! Just a few sessions & you'll experience a surge in positivity and witness your fitness soar!</span>
                </div>
            </div>
            <MDBContainer className="all-videos-text">
                <span style={{fontSize: window.innerWidth/1920 > 1 ? 28 : 22}}>
                Seeking varied workouts that push you to your limits?
                    Let our expert trainers guide you towards your fitness goals!
                </span>
                <button type="button" style={{width: window.innerWidth/1920 > 1 ? 341 : (window.innerWidth/1920)*341, height: window.innerWidth/1920 > 1 ? 117 : (window.innerWidth/1920)*117}} onClick={() => {
                    if(context.isLoggedIn()){
                        navigate("/videos"),
                        context.onSetActiveItem("2");
                    }else{
                        context.openLoginModal("signin")
                        context.onToVideosPage();
                    }
                }}><span style={{fontSize: window.innerWidth/1920 > 1 ? 26 : 22}}>ALL VIDEOS</span></button>
            </MDBContainer>
        </section>
       
        <section className="faq-section" id="faq">
             {/*
            <MDBContainer className="faq">
                <div className="faq-title">
                    <span style={{fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*150}}>
                    Frequently Asked Questions 
                    </span>
                </div>
                <Gyik/>
            </MDBContainer>
              */}
        </section>
          
    </MDBContainer>
  );
};

export default LandingPage;
