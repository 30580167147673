// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import search from '../../assets/media/icons/search.png'
import gymflix_logo from '../../assets/media/logo/GYMFLIX.png'
import trainer_background from '../../assets/media/images/trainer-background.png'
import video from '../../assets/media/videos/video_1.mp4'

// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const CategoryDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [open, setOpen] = useState(false);
  const [starttime, setStarttime] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [trainer, setTrainer] = useState(location.state?.trainer !== undefined ? location.state?.trainer : {})
  const [videoList, setVideoList] = useState(location.state?.videoList !== undefined ? location.state?.videoList : {})
  const [trainerList, setTrainerList] = useState(location.state?.trainerList !== undefined ? location.state?.trainerList : {})
  const [categoryName, setCategoryName] = useState(location.state?.categoryName !== undefined ? location.state?.categoryName : "")
  const [searchText, setSearchText] = useState("");
  


  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 100)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 300)
  }, []);

  const onPutVideoToBookmark = (id) => {
    context.interAxios.put('/bookmark/' + id, function(response) {
        context.interAxios.get('/bookmark', function(response) {
          context.onSetBookmarkList(response.data)
        });
        
    });
  }

  const onDeleteVideoFromBookmark = (id) => {
      context.interAxios.delete('/bookmark/' + id, function(response) {
          context.interAxios.get('/bookmark', function(response) {
            context.onSetBookmarkList(response.data)
          });
          
      });
  }

  const onSearchText  = (event) => {
    setSearchText(event.target.value);
  };
 
  return (
    <>
      {trainer && (
        <>
          <button className="back-button-trainer" type="button" onClick={() => navigate('/videos')}>
            <img src={prev} alt="back"/><span>Back</span>
          </button>
          <section className="categorydetail-section">
           
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
              <div className="trainer-name">
                <span >{categoryName}</span>
              </div>
            </MDBContainer>  
          </section>
          <section className="more-videos-section">
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
            <div className="input-div">
                <MDBInput label={<><img src={search} alt="search"/><span>Keresés</span></>} id='form1' type='text' value={searchText} onChange={onSearchText} />
            </div>
            <MDBRow  className="m-0 pb-5">
              {videoList && categoryName && (<>
                {videoList.filter(k => (k.category === categoryName)).filter(k => k.name.toLowerCase().includes(searchText.toLowerCase())).map((k,n) => {
                  let array = k.name.split(" ")
                  return(
                  <MDBCol lg="3" md="3" size="4" key={n} className="pt-5 px-0">
                    <div className="videocard-more  d-none d-lg-block px-2" key={n} >
                      <div className="background-img">
                      <img src={k.pictureUrl} alt="thumbnail-image" className="thumbnail-image"/>
                      <img src={gymflix_logo} alt="gymflix" id="gymflix" className="gymflix-logo"/>
                        <div className="description" 
                             style={{justifyContent: k.overlayTextPositionId === 1 ? "flex-start" : "flex-end", 
                                     alignItems: k.overlayTextPositionId === 2 ? "flex-start" : "flex-end"
                                    }}>
                            <span 
                              style={{textAlign: k.overlayTextPositionId === 1 ? "start" : "end", 
                                  fontFamily: k.overlayTextFontId === 1 ? "Impact" : k.overlayTextFontId === 2 ? "Dreams" : "din-condensed", 
                                  color: k.overlayTextColorId === 1 ? "#55FFDA" : k.overlayTextColorId === 2 ? "#FF557B" : "#FFFFFF",
                                  lineHeight: k.overlayTextFontId === 1 ? "100%" : k.overlayTextFontId === 2 ? "18px" : "100%",
                                  fontSize: k.overlayTextFontId === 2 ? "4px" : k.overlayTextFontId === 1 ? "19px" : "22px",
                                }}>
                                  {array.map((l,m) => {
                                  return(
                                    <span style={{
                                      fontSize: k.overlayTextFontId === 2 ? l.split("").length > 18 ? 4*18/l.split("").length + "px" : "4px" :
                                      k.overlayTextFontId === 1 ? l.split("").length > 28 ? 19*28/l.split("").length + "px" : "19px" :
                                      l.split("").length > 28 ? 22*28/l.split("").length + "px" : "22px"}} id="test">
                                      {l + " "}
                                    </span>
                                            
                                          
                                )})}
                            </span>
                        </div>
                        <div style={{position: "absolute", width: "100%", height: "80%",
                                     background:  "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 24%, rgba(0,0,0,0.24) 45.11%, #000000 100%)",
                                     transform: k.overlayTextPositionId === 2 ? "scale(-1)" : "unset",
                                     bottom: k.overlayTextPositionId === 1 ? "-5px" : "unset",
                                     top: k.overlayTextPositionId === 2 ? "-5px" : "unset",
                                    }}/>
                      <div className="description-hover">
                          <div className="trainer-div">
                              <img src={trainer.profileImage?.thumbnailUrl} alt="trainer"/>
                              <span>{trainer.firstName + " " + trainer.lastName}</span>
                          </div>
                          <button className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: trainerList.filter(l => l.trainerTag === k.trainerTag) }})}}><img src={video_play} alt="player"/></button>
                          <div className="time">
                              <img src={video_clock} alt="timer"/>
                              <span>{parseInt(k.duration/60) + "minutes"}</span>
                          </div>
                          <div className="bookmark-div">
                            <div className="more-text">
                                <span>{k.name}</span>
                            </div>
                            <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                  () => {onDeleteVideoFromBookmark(k.id)} : () => {onPutVideoToBookmark(k.id)}}>
                              <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                            </button>
                          </div>
                      </div>
                      </div>
                    </div>
                    <div className="videocard-more-trainer  d-block d-lg-none px-2" key={n} >
                      <div className="background-img" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: trainerList.filter(l => l.trainerTag === k.trainerTag) }})}}>
                        <div className="more-text-respo"  style={{justifyContent: k.overlayTextPositionId === 1 ? "flex-start" : "flex-end", 
                                     alignItems: k.overlayTextPositionId === 2 ? "flex-start" : "flex-end"
                                    }}>
                        <span 
                              style={{textAlign: k.overlayTextPositionId === 1 ? "start" : "end", 
                                  fontFamily: k.overlayTextFontId === 1 ? "Impact" : k.overlayTextFontId === 2 ? "Dreams" : "din-condensed", 
                                  color: k.overlayTextColorId === 1 ? "#55FFDA" : k.overlayTextColorId === 2 ? "#FF557B" : "#FFFFFF",
                                  lineHeight: k.overlayTextFontId === 1 ? "100%" : k.overlayTextFontId === 2 ? "14px" : "100%",
                                  fontSize: k.overlayTextFontId === 2 ? "2px" : k.overlayTextFontId === 1 ? "13px" : "16px",
                                }}>
                                  {array.map((l,m) => {
                                  return(
                                    <span style={{
                                      fontSize: k.overlayTextFontId === 2 ? l.split("").length > 18 ? 2*18/l.split("").length + "px" : "2px" :
                                      k.overlayTextFontId === 1 ? l.split("").length > 28 ? 13*28/l.split("").length + "px" : "13px" :
                                      l.split("").length > 28 ? 16*28/l.split("").length + "px" : "16px"}} id="test">
                                      {l + " "}
                                    </span>
                                            
                                          
                                )})}
                            </span>
                        </div>
                        <img src={k.pictureUrl} alt="thumbnail-image"/>
                      </div>
                    </div>
                  </MDBCol>
                )})}
              </>

              )}
                        
            </MDBRow>
            </MDBContainer>
          </section>
        </>
      )}
    
    </>


  );
};

export default CategoryDetailPage;
