// REACT IMPORTS
import React, { useContext, useState } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";

// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';

//images
import gymflix_footer from '../../../assets/media/icons/GYMFLIX-BLACK.png'

const Footer = (props) => {
  const context = useContext(AppContext);

  const [email, setEmail] = useState("");

  const onGetAllNewsletter = () => {
    let self = this;
    if(email !== "") {
      context.interAxios.post('/newsletter', {email: email}, function(response) {
        setEmail("");
        context.openLoginModal("hirlevel");
      });
    }else {
      alert("Add meg az emailcímedet!")
      
    }
  }
  
  return (
    <footer className="gymfix-footer" id="contact">
      <div className="d-flex align-items-center footer-top">
        <MDBRow style={{height:"100%", width: "100%"}} className="m-0">
          <MDBCol lg="auto" md="12" sm="12" size="12" center className="px-0 footer-col-1">
            <img src={gymflix_footer}
                alt=''
                loading='lazy'/>
          </MDBCol>
       
          <MDBCol  lg="auto" md="12" sm="12" size="12" center className="p-0 footer-col-2">
            <div className="coulom_2">
              <div className="iratkozzfel-title">
                <span>JOIN OUR COMMUNITY!</span>
              </div>
              <div className="iratkozzfel-text">
                <span>We will keep you updated about our latest lessons and more…</span>
              </div>
              <div className="email-form">
                <MDBInput value={email}
                          name='email'
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          label='E-mail'/>
                <MDBBtn type="btn" className="email-button" onClick={() => onGetAllNewsletter()}>
                  <span>
                    JOIN
                  </span>
                </MDBBtn>
              </div>
            </div>
           
          </MDBCol>
        </MDBRow>
      </div>
      <div className="copyright">
        <a href="#" target="_blank" rel="noopener noreferrer">
        <span>TERMS & CONDITIONS</span></a>
      </div>
      
    </footer>
  );
};

export default Footer;
