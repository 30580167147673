// react imports
import React, { useContext, useState, useEffect } from "react";
import SingleImageUploader from '../image-uploader/SingleImageUploader';
import ImageUploader from '../image-uploader/ImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



// CUSTOM COMPONENTS
import AppContext from '../../AppContext';


import {  MDBContainer
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const TrainerPage = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  

 
  // STATES
  const [trainer, setTrainer] = useState(undefined);
  const [photos, setPhotos] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [description, setDescription] = useState();
  const [isRenderTrue, setIsRenderTrue] = useState(false);

  useEffect(() => {
    if(context.isLoggedIn()){
        context.interAxios.get('/trainer/me', function(response) {
            setTrainer(response.data)
            setDescription(response.data.description)
            
        });
    }
    if(!context.isLoggedIn()){
        setTrainer(undefined)
    }
  }, [context.isLoggedIn()]);

  const onUpdateTrainer = () => {
    console.log(imageFile)
    if(imageFile !== undefined && imageFile !== null){
        const formData = new FormData
        formData.append("file", imageFile);
        context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
            const profileImageId = response.data.id;
            if(photos !== undefined) {
                let prom=[];
                let array = photos;
                console.log(array)
                for(let i=0; i<photos.length; i++){
                    if(!photos[i].toBeRemoved && photos[i].new){
                        const formData = new FormData
                        formData.append("file", photos[i].file);
                        prom.push(context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                            array[i].id=response.data.id;
                        }));
                    }
                }
                Promise.all(prom).then(() => 
                    context.interAxios.patch('/trainer/me',
                    {
                        description: description,
                        profileImageId: profileImageId,
                        imageIds: array.filter((k)=>(!k.toBeRemoved)).map((k)=>(k.id))
                      },
                     function(response) {
                        setImageUrl(response.data.profileImage?.url)
                        setPhotos(response.data.images)
                        setTrainer(response.data)
                    })
                );
            }else {
                context.interAxios.patch('/trainer/me',
                {
                    description: description,
                    profileImageId: profileImageId
                  },
                 function(response) {
                    setImageUrl(response.data.profileImage?.url)
                    setPhotos(response.data.images)
                    setTrainer(response.data)
                })
            }
        });
    }else {
        if(photos !== undefined) {
            let prom=[];
            let array = photos;
            for(let i=0; i<photos.length; i++){
                if(!photos[i].toBeRemoved && photos[i].new){
                    const formData = new FormData
                    formData.append("file", photos[i].file);
                    prom.push(context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                        array[i].id=response.data.id;
                    }));
                }
            }
            Promise.all(prom).then(() => {
                if(imageFile === null){
                    context.interAxios.patch('/trainer/me',
                    {
                        description: description,
                        profileImageId: null,
                        imageIds: array.filter((k)=>(!k.toBeRemoved)).map((k)=>(k.id))
                    },
                    function(response) {
                        context.interAxios.delete('/media/' + trainer.profileImage.id, function(response) {
                            
                        });
                        setImageUrl(response.data.profileImage?.url)
                        setPhotos(response.data.images)
                        setTrainer(response.data)
                    })
                } else {
                    context.interAxios.patch('/trainer/me',
                    {
                        description: description,
                        imageIds: array.filter((k)=>(!k.toBeRemoved)).map((k)=>(k.id))
                    },
                    function(response) {
                        setImageUrl(response.data.profileImage?.url)
                        setPhotos(response.data.images)
                        setTrainer(response.data)
                    })
                }
            }
            );
        }else {
            context.interAxios.patch('/trainer/me',
            {
                description: description
              },
             function(response) {
                setImageUrl(response.data.profileImage?.url)
                setPhotos(response.data.images)
                setTrainer(response.data)
            })
        }
    }
    
  }

  return context.isLoggedIn() && cookies.get('client')?.role === "TRAINER" && trainer ? (
    <MDBContainer style={{maxWidth: "1920px"}} className="trainer-page">
        <div className="profile-photo">
            <h3>
                Add profile photo
            </h3>
            
                <SingleImageUploader
                    uploadBtnMessage={"Add photo"}
                    imageUrl= {trainer.profileImage?.url}
                    setNewImageUrl = {(newImageFile) => setImageFile(newImageFile)}
                />
            

        </div>
        <div className="trainer-name">
            <h3>
                Name
            </h3>
            <span>
                {trainer.firstName + " " + trainer.lastName}
            </span>
        </div>
        <div className="about-me">
            <h3>
                About me
            </h3>
            <CKEditor
                    id="description"
                                    data={trainer.description === undefined ? "" : trainer.description}
                    editor={ ClassicEditor }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data);
                    } }
            />
        </div>
        <div className="trainer-photos">
            <h3>
                Add photos
            </h3>
            {trainer.images && (
                <ImageUploader
                    selectedImages={photos.length > 0 ? photos : trainer.images}
                    setSelectedImages={setPhotos}
                    uploadBtnMessage={"Add photos"}
                />
            )}
            

        </div>
        <div className="save-button">
            <button type="button" onClick={onUpdateTrainer}><span>Save my profile</span></button>
        </div>
    </MDBContainer>

  )
  : (
    <MDBContainer style={{maxWidth: "1920px"}} className="landing-page">
        <section className="signin-section" style={{minHeight: "80vh"}}>
            <div className="title-text">
                <span style={{fontSize: window.innerWidth/1920 > 1 ? 100 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*110 : (window.innerWidth/1920)*120}}>PERSONAL <span style={{color: "#FF557B"}}>TRAINING</span> DONE BY EXPERTS.</span>
            </div>
           
           
        </section>
    </MDBContainer>
  );
};

export default TrainerPage;
