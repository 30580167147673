// react imports
import React, { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev.png'

import video from '../../assets/media/videos/video_1.mp4'
import ReactPlayer from 'react-player'
import { MDBContainer
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const VideoPlayPage = (props) => {

  const location = useLocation();

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [isIframe, setIsIframe] = useState(false);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [isVideoHoverTwo, setIsVideoHoverTwo] = useState(false);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };

  
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setIsVideoHover(true)}, 500)
  }, []);


  return (
    <MDBContainer fluid className="videoplay-container">
      <button type="button" onClick={() => {
                            if(location.state.categoryName !== undefined){
                              navigate('/videos', { state: { categoryName: location.state.categoryName, isTrainerModal: location.state.isTrainerModal, trainer:  location.state.trainer  } })
                            }else {
                              navigate('/videos')
                            }}}><img src={prev} alt="back"/><span>Back</span></button>
      <section className="first-section" id="">
        {!isIframe ? 
        <div className={isVideoHover ? "reactplayer-div hover-effect" : "reactplayer-div"}>
          <ReactPlayer
              className=''
              url= {video}
              width='100%'
              height='100%'
              controls = {false}
              onEnded = {() => {setIsVideoHover(false); setTimeout(() => {
                setIsIframe(true); }, 400); setTimeout(() => {
                  setIsVideoHoverTwo(true)}, 1500)} }
              playing={true}

          />
        </div>
        :
        <div className={isVideoHoverTwo ? "iframe-div hover-effect" : "reactplayer-div"}>
            <iframe src={location.state.fullUrl + "&autoplay=1"}  width={videoHeight < videoWidht*0.5625 ? videoHeight/0.5625 :  videoWidht} height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} allowFullScreen="allowFullScreen" allow="autoplay"
              mozallowfullscreen="mozallowfullscreen" 
              msallowfullscreen="msallowfullscreen" 
              oallowfullscreen="oallowfullscreen" 
              webkitallowfullscreen="webkitallowfullscreen"></iframe>
        </div>
          }
      </section>
    </MDBContainer>


  );
};

export default VideoPlayPage;
