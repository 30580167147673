// react imports
import React, { useContext } from "react";


import AppContext from '../../AppContext';

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn
} from 'mdb-react-ui-kit';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param onDelete: () => void
 * @returns
 */
const ProfileDeleteModal = (props) => {
  

  const context = useContext(AppContext);


  const  onDeleteClientMe = () => {
    context.interAxios.delete('/client/me', function(response) {
      context.onLogout()
      props.hideModal();
    });
  }

  return (
    <MDBModal
      className="signup-modal"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Profil törlése</span></MDBModalTitle>

          <div className="d-flex justify-content-center align-items-center pb-4">
          <p>Biztosan törlöd a profilodat? Amennyiben így döntesz, meglévő előfizetésed végéig még használhatod a videótárat. Ezt követően azonban minden adatod és a fiókod elvész.</p>
          </div>

          <div className="d-flex justify-content-center align-items-center">
          <button className="rergistration-button  green" onClick={() => {
              onDeleteClientMe();  setTimeout(() => props.hideModal(), 500);
            }}>
                 <span>Profil törlése</span>
            </button>

            <button
              className="rergistration-button  white"
              onClick={props.hideModal}
            >
              <span>Mégsem</span>
            </button>
          </div>

            

           
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ProfileDeleteModal;

