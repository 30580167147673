import React, { Component } from 'react';
import { useMemo , useContext } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}


class AppContextProvider extends Component {
  state = {
    interAxios: new InterAxios('App', getAuthToken),
    isGyik: true,
    activeItem: window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videos" ? "1" : "2",
    isToVideoPage: false,
    isPageRender: false
    
  }

  constructor(props) {
    super(props);
  }
  
  
  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }


  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  clearState = () => {

  }

  onSignIn = (responseData) => {
    console.log(responseData)
    cookies.set('authtoken', {
        token: responseData.authToken
      }, { path: '/'}
    );
    cookies.set('client', {
      status: responseData.subscriptionStatus,
      role: responseData.role
    }, { path: '/'}
    
    );
    if(responseData.subscriptionStatus === "INACTIVE"){
      setTimeout(() => {
      //this.openLoginModal("firstwarning")}, 500)
      this.openLoginModal("lastwarning")}, 500)
    }
    if(responseData.subscriptionStatus === "INACTIVE_24"){
      setTimeout(() => {
      this.openLoginModal("lastwarning")}, 500)
    }
    this.setState({loggedIn: true, isGyik: false});
  }

  /*onSignIn = () => {
    this.setState({loggedIn: true, isGyik: false});
  }*/

  onLogout = () => {
   cookies.remove('authtoken', { path: '/'});
    window.localStorage.removeItem('state');
    this.setState({loggedIn: false, isGyik: true, isToVideoPage: false});
  }

  /*onLogout = () => {
     this.setState({loggedIn: false, isGyik: true});
   }*/

  isLoggedIn = () => {
      let cookieToken = getAuthToken();
      return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
      //return this.state.loggedIn ? true : false;
  }

  parseJwt = (token) => {
      try {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          return JSON.parse(jsonPayload);
      } catch (e) {
          console.log(e);
        return null;
      }
  };

  openLoginModal = (modalName) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName
    });
  }
  
  onToVideosPage = () => {
    this.setState({isToVideoPage: true})
  }

  isLoginModalOpened = () => {
    return this.getState()?.loginModalOpened;
  }
  closeLoginModal = () => {
    this.setState({
      loginModalOpened: false,
    });
    document.body.style.overflowY = 'scroll'
  }

  onHideScroll = (value) => {
    this.setState({isScroll: value})
  }

  handleWindowSizeChange = (videoWidht) => {
    this.setState({videoWidht: videoWidht});
  };

  onSetActiveItem = (tab) => {
    this.setState({activeItem: tab})
  }

  onSideNavOpen = (params) => {
    this.setState({isSideNavOpen: params})
  }

  onSetLiveTime = (time) => {
    this.setState({liveTime: time})
  }

  onPaymentProcess = (paymentUrl, paymentService) => {
    this.setState({paymentUrl: paymentUrl, paymentService: paymentService})
  }

  onRenderTrue = () => {
    this.setState({isRender: true})
  }

  onRenderFalse = () => {
    this.setState({isRender: false})
  }

  onSetBookmarkList = (bookmarkList) => {
    this.setState({bookmarkList: bookmarkList})
    
  }


  render() {
      return (
          <AppContext.Provider
              value= {{
                  interAxios: this.state.interAxios,
                  isLoggedIn: this.isLoggedIn,
                  onSignIn: this.onSignIn,
                  onLogout: this.onLogout,
                  getState: this.getState,
                  clearState: this.clearState,
                  isLoginModalOpened: this.isLoginModalOpened,
                  openLoginModal: this.openLoginModal,
                  closeLoginModal: this.closeLoginModal,
                  onHideScroll: this.onHideScroll,
                  handleWindowSizeChange: this.handleWindowSizeChange,
                  onSetActiveItem: this.onSetActiveItem,
                  onSideNavOpen: this.onSideNavOpen,
                  onSetLiveTime: this.onSetLiveTime,
                  onPaymentProcess: this.onPaymentProcess,
                  onRenderTrue: this.onRenderTrue,
                  onRenderFalse: this.onRenderFalse,
                  onToVideosPage: this.onToVideosPage,
                  onSetBookmarkList: this.onSetBookmarkList,
                  isPageRender: this.state.isPageRender
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;