// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/video-play.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty.png'
import gymflix_logo from '../../assets/media/logo/GYMFLIX.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import { Fotolist } from '../../data/coverFotok';

const CarouselComponent = (props) => {

  //MDBAlert

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);

  // STATES
  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [starttime, setStarttime] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image")?.clientWidth*9/16);

   // react router navigation
   const navigate = useNavigate();


  const responsive = {
    desktop2: {
      breakpoint: { max: 4000, min: 1920 },
      items: 6,
      partialVisibilityGutter: 3
    },
    desktop: {
      breakpoint: { max: 1920, min: 1400},
      items: 5,
      partialVisibilityGutter: 3
    },
    tablet2: {
      breakpoint: { max: 1400, min: 1100 },
      items: 4,
      partialVisibilityGutter: 3
    },
    tablet: {
      breakpoint: { max: 1100, min: 740 },
      items: 3,
      partialVisibilityGutter: 3
    },
    small: {
        breakpoint: { max: 450, min: 0 },
        items: 2,
        partialVisibilityGutter: 1
    }
  };

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideocardHeight(document.getElementById("thumbnail-image")?.clientWidth*9/16)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setVideocardHeight(document.getElementById("thumbnail-image")?.clientWidth*9/16)
  }, [document.getElementById("thumbnail-image")?.clientWidth]);
 


  const truncateString = (str, num) => {
    
    if (str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }

  const swiper = new Swiper('.swiper', {
    // Optional parameters
    modules: [FreeMode],
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      // when window width is >= 640px
      760: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    },
    direction: 'horizontal',
    loop: false,
    effect: 'slide',
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });

 
  return (
    <div className="more-carousel" style={{height: videocardHeight + 50}}>
      <div className="d-none d-lg-block">
        <Carousel
                additionalTransfrom={0} 
                swipeable={true}
                draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
                showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={true}
                slidesToSlide={4}
                centerMode={false}
                keyBoardControl={true}
                customTransition={window.matchMedia('(max-device-width: 400px)').matches ? "transform 100ms ease-in-out" : "transform 1000ms ease-in-out"}
                transitionDuration={window.matchMedia('(max-device-width: 400px)').matches ? 100 : 1000}
                containerClass={isFirstStep ? "carousel-container firststep" : "carousel-container"}
                removeArrowOnDeviceType={["small", "mobileclaim"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                afterChange={(previousSlide, { currentSlide, onMove }) => {
                    setIsFirstStep(false);
                   
                }}
        >
          {props.videoList.filter(k => ((k.category === props.category || props.category === "Legújabb feltöltések" || props.category === "My favourites"))).map((k,n) => {
              let array = k.name.split(" ")
              return(
                  <div className="videocard-more" key={n} id="touchsurface" style={{height: videocardHeight}}>
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image" className="thumbnail-image"/>
                        <img src={gymflix_logo} alt="gymflix" id="gymflix" className="gymflix-logo"/>
                        <div className="description" 
                             style={{justifyContent: k.overlayTextPositionId === 1 ? "flex-start" : "flex-end", 
                                     alignItems: k.overlayTextPositionId === 2 ? "flex-start" : "flex-end"
                                    }}>
                            <span 
                              style={{textAlign: k.overlayTextPositionId === 1 ? "start" : "end", 
                                  fontFamily: k.overlayTextFontId === 1 ? "Impact" : k.overlayTextFontId === 2 ? "Dreams" : "din-condensed", 
                                  color: k.overlayTextColorId === 1 ? "#55FFDA" : k.overlayTextColorId === 2 ? "#FF557B" : "#FFFFFF",
                                  opacity: k.overlayTextColorId === 3 ? .8 : 1,
                                  lineHeight: k.overlayTextFontId === 1 ? "100%" : k.overlayTextFontId === 2 ? "22px" : "100%",
                                  fontSize: k.overlayTextFontId === 2 ? "4px" : k.overlayTextFontId === 1 ? "19px" : "22px",
                                  textTransform: "uppercase"
                                }}>
                                  {array.map((l,m) => {
                                  return(
                                    <span style={{
                                      fontSize: k.overlayTextFontId === 2 ? l.split("").length > 18 ? 518/l.split("").length + "px" : "5px" :
                                      k.overlayTextFontId === 1 ? l.split("").length > 28 ? 24*28/l.split("").length + "px" : "24px" :
                                      l.split("").length > 28 ? 26*28/l.split("").length + "px" : "28px"}} id="test">
                                      {l + " "}
                                    </span>
                                            
                                          
                                )})}
                            </span>
                        </div>
                        <div style={{position: "absolute", width: "100%", height: "80%",
                                     background:  "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 44.99%, rgba(0,0,0,0.8) 71.68%, rgba(0,0,0,0.92) 100%, #000000 100%)",
                                     transform: k.overlayTextPositionId === 2 ? "scale(-1)" : "unset",
                                     bottom: k.overlayTextPositionId === 1 ? "-5px" : "unset",
                                     top: k.overlayTextPositionId === 2 ? "-5px" : "unset",
                                    }}/>
                        <div className="description-hover">
                          <div className="trainer-div">
                              <img src={props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.profileImage?.thumbnailUrl} alt="trainer"/>
                              <span>{props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.firstName + " " + props.trainerList.filter(l => l.trainerTag === k.trainerTag)[0]?.lastName}</span>
                          </div>
                          <button className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: props.trainerList.filter(l => l.trainerTag === k.trainerTag) } })}}><img src={video_play} alt="player"/></button>

                          <div className="time">
                              <img src={video_clock} alt="timer"/>
                              <span>{parseInt(k.duration/60) + "minutes"}</span>
                          </div>
                          
                          <div className="bookmark-div">
                            <div className="more-text">
                                <span>{truncateString(k.name, 40)}</span>
                            </div>
                            <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                              () => {props.onDeleteVideoFromBookmark(k.id)} : () => {props.onPutVideoToBookmark(k.id)}}>
                              <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
          )})}
        </Carousel>
      </div>
      <div className="swiper d-block d-lg-none">
        <div className="swiper-wrapper">
          {props.videoList.filter(k => ((k.category === props.category || props.category === "Legújabb feltöltések" || props.category === "My favourites")&& k.category !== "Nyereményjáték")).map((k,n) => {  
            return(
              <>
              {starttime && (
                <div class="swiper-slide" key={n}>
                <div className="videocard-more-respo">
                    <div className="background-img" onClick={() => { navigate('/videodetail', { state: { video: k, trainer: props.trainerList.filter(l => l.trainerTag === k.trainerTag) } })}}>
                      <img src={k.pictureUrl} alt="thumbnail-image"/>
                      <div className="more-text">
                        <span>{truncateString(k.name, 20)}</span>
                      </div>
                    </div>
                </div>
                </div>
              )}
              </>
            )
          })}
        </div>
      </div>
    </div>


  );
};

export default CarouselComponent;
