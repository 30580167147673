// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import { useSwipeable } from 'react-swipeable'
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

import prev from '../../assets/media/icons/prev pink.png'
import next_button from '../../assets/media/icons/next pink.png'
import { Fotolist } from '../../data/coverFotok';
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';

const CarouselComponentTrainer = (props) => {

  //MDBAlert

  // REACT ROUTER PARAMS - for processing url
  const cardsRef  = useRef(null);
  // STATES
  const [starttime, setStarttime] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
 
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

 
   const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1250 },
      items: 5,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max:1250, min: 1080 },
      items: 4,
      partialVisibilityGutter: 3
    },
    small: {
        breakpoint: { max: 1079.99, min: 0 },
        items: 3,
        partialVisibilityGutter: 1
    }
  };



  const swiper = new Swiper('.swiper-trainer', {
    // Optional parameters
    modules: [FreeMode],
    //slidesPerView: 3,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      // when window width is >= 640px
      760: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    },
    //spaceBetween: 0,
    direction: 'horizontal',
    loop: false,
    effect: 'slide',
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });


  const CustomButtonGroup = ({ next, previous }) => {
    return (
      
        <MDBContainer style={{maxWidth: videoWidht}} className="button-container-trainer">
          <div className="custom-button-group">
            <button className={isFirstStep ? "left-button firststep" : "left-button"} onClick={() => previous()}><img src={prev} alt="prev"/></button>
            <button className="right-button" onClick={() => next()}><img src={next_button} alt="next"/></button>
          </div>
        </MDBContainer>
      
    );
  };
  
  return (
    <div>
      <MDBContainer style={{maxWidth: videoWidht > 1599.99 ? videoWidht-363 : videoWidht > 1080 ? videoWidht-220 : videoWidht-20, padding: 0, position: "relative"}} className="trainer-carousel">
        <div className="d-none d-lg-block">
          <Carousel
              additionalTransfrom={0} 
              swipeable={true}
              draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
              showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
              responsive={responsive}
              infinite={true}
              renderArrowsWhenDisabled={true}
              renderDotsOutside={true}
              renderArrowsOutside={true}
              slidesToSlide={5}
              centerMode={false}
              keyBoardControl={true}
              customTransition={window.matchMedia('(max-device-width: 400px)').matches ? "transform 100ms ease-in-out" : "transform 1000ms ease-in-out"}
              transitionDuration={window.matchMedia('(max-device-width: 400px)').matches ? 100 : 1000}
              containerClass={isFirstStep ? "carousel-container firststep" : "carousel-container"}
              removeArrowOnDeviceType={["small", "mobileclaim"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              afterChange={(previousSlide, { currentSlide, onMove }) => {
                  setIsFirstStep(false);
              }}
              renderButtonGroupOutside={true} customButtonGroup={<CustomButtonGroup/>}
              >
              {props.trainerList?.map((k,n) => {
                  
                  return(
                      <div className="trainercard" key={n} id="touchsurface">
                          <div className="background-img" onClick={() => {
                              props.getTrainer(k)}}>
                            <img src={k.profileImage?.thumbnailUrl} alt="thumbnail-image"/>
                            <div className="title">
                              <span>{k.firstName + " " + k.lastName}</span>
                            </div>
                            <button type="button" >
                                <span>SHOW PLANS</span><img src={next_button} alt="next"/>
                            </button>
                          </div>
                      </div>
              )})}
          </Carousel>
        </div>
        <div className="swiper-trainer d-block d-lg-none">
          <div className="swiper-wrapper">
            
            {props.trainerList?.map((k,n) => {
                  
              return(
                <>
                  {starttime && (
                  <div className="swiper-slide" key={n}>
                    <div className="trainercard-respo" >
                        <div className="background-img" onClick={() => {
                            props.getTrainer(k)}}>
                          <img src={k.profileImage?.thumbnailUrl} alt="thumbnail-image"/>
                          <div className="title">
                            <span>{k.firstName + " " + k.lastName}</span>
                          </div>
                        </div>
                    </div>
                  </div>
                  )}
                </>
              )})}
            </div>
         
        </div>
      </MDBContainer>
    
    </div>

  );
};

export default CarouselComponentTrainer;
