// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
   MDBCol, 
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";

// OTHER IMPORTS
import PasswordInput from './PasswordInput';
import marker from '../../assets/media/images/marker.png'

class ProfileEditForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    newPassword: "",
                    confirmPassword: "",
                    fullName: "",
                    address: "",
                    postalCode: "",
                    city: "",
                    country: "",
                    isPasswordchange: false,
                    //paymentService: "NONE"       
      };
    };

     componentDidMount() {
      this.getClientMe();
     }

     getClientMe = () => {
      let self = this;
      this.props.context.interAxios.get('/client/me', function(response) {
        console.log(response.data)
        self.setState({ email: response.data.email,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        fullName: response.data.billing.name,
                        address: response.data.billing.address,
                        postalCode: response.data.billing.postcode,
                        city: response.data.billing.location,
                        country: response.data.billing.country,
                        paymentService: response.data.paymentService !== undefined ? response.data.paymentService : "NONE",
                        paymentServiceStatus: response.data.paymentServiceStatus,
                        delete: response.data.delete
                      });
                        
      });
    }

    onNewCard = () => {
      let self = this;
      if(this.state.paymentService === "SIMPLEPAY"){
        this.props.context.interAxios.post('/client/me/newcard', undefined, function(response) {
          self.props.context.onPaymentProcess(response.data.paymentUrl, "SIMPLEPAY");
          self.props.context.openLoginModal("succesRegistration");
          let url = response.data.paymentUrl
          setTimeout(() => {
            //self.props.context.closeLoginModal();
            self.props.context.onLogout()
            window.open(url, '_self').focus();
            
          }, 2000);
        });
      }
      if(this.state.paymentService === "PAYPAL"){

        let url = "https://www.paypal.com/signin"
        this.props.context.onPaymentProcess(url, "PAYPAL");
        this.props.context.openLoginModal("succesRegistration");
        setTimeout(() => {
          //self.props.context.closeLoginModal();
          //context.onLogout()
          window.open(url, '_blank').focus();
          
        }, 2000);
      }
    }

    onNewSubscription = (paymentService) =>  {
        let self = this;
        this.props.context.interAxios.post('/client/me/subscribe', { paymentService: paymentService }, function(response) {
          if(paymentService === "SIMPLEPAY"){
            self.props.context.onPaymentProcess(response.data.paymentUrl, "SIMPLEPAY");
            self.props.context.openLoginModal("succesRegistration");
            let url = response.data.paymentUrl
            setTimeout(() => {
              //self.props.context.closeLoginModal();
              self.props.context.onLogout()
              window.open(url, '_self').focus();
              
            }, 2000);
          }
          if(paymentService === "PAYPAL"){
            self.props.context.onPaymentProcess(response.data.paymentUrl, "PAYPAL");
            self.props.context.openLoginModal("succesRegistration");
            let url = response.data.paymentUrl
            setTimeout(() => {
              //self.props.context.closeLoginModal();
              self.props.context.onLogout()
              window.open(url, '_self').focus();
              
            }, 2000);
          }
         
        });
        
    }


    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      this.props.context.interAxios.put('/client/me', {
        email: this.state.email,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        billing: {
          name: this.state.fullName,
          postcode: this.state.postalCode,
          location: this.state.city,
          address: this.state.address,
          vatCode: "",
          country: this.state.country,
          region: "",
        }
      }, function(response) {
       self.getClientMe();
      });
      this.props.hideModal();
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    onPasswordCahngeShow = () => {
      this.setState({isPasswordchange : true})
    }
    onPasswordCahngeHide = () => {
      this.setState({isPasswordchange : false})
    }

    onNewPassword = () =>  {
      let self = this;
      if(this.state.newPassword === this.state.confirmPassword) {
        this.props.context.interAxios.put('/auth/password', {
          oldPassword: this.state.password,
          password: this.state.newPassword
        }, function(response) {
          self.props.loginEvent(self.state.email, self.state.newPassword)
          self.setState({newPassword: "", password: "", confirmPassword: ""});
          self.onPasswordCahngeHide();
        });
      }else {
        alert('Ellenőrizd a megadott jelszavakat!')
      }
     
    }

    undeleteProfile = () => {
      let self = this;
      this.props.context.interAxios.post('/client/me/undelete', undefined, function(response) {
        console.log("itt vagyok")
        self.getClientMe();
      });
    
    }


  
  render() {
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-edit-form" autoComplete="off">
                <div className="white-panel">
                  {/** PASSWORDs **/}
                  <MDBRow className="py-2 m-0">
                    <MDBCol md='6' className="p-0">
                    <MDBRow className="pb-2 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="lastName">Last name</label><br/>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                    <MDBRow className="pb-2 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="firstName">First name</label><br/>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                
                  {/** EMAIL **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="email">E-mail address</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.nameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'>
                          <span className="modal-title-form">Billing date</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="py-2 m-0">
                        <MDBCol md='12'>
                          <label htmlFor="address">Public name</label>
                          <input
                            id='fullName'
                            name='fullName'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.fullName}
                            ref={this.props.nameRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.addressRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'>
                          <label htmlFor="address">Address</label>
                          <input
                            id='address'
                            name='address'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.address}
                            ref={this.props.addressRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.postcodeRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="m-0">
                        <MDBCol md='5'>
                        <label htmlFor="postalCode">Postal code</label>
                          <input
                            id='postalCode'
                            name='postalCode'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.postalCode}
                            ref={this.props.postcodeRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.cityRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                        <MDBCol md='7'>
                        <label htmlFor="city">City</label>
                          <input
                            id='city'
                            name='city'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.city}
                            ref={this.props.cityRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.countryRef.current.focus()
                              }
                            }}
                            required
                          /> 
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pb-5 m-0">
                        <MDBCol md='12'>
                        <label htmlFor="postalCode">Country</label>
                          <input
                            id='country'
                            name='postalCode'
                            type='text'
                            onChange={this.changeHandler}
                            value={this.state.country}
                            ref={this.props.countryRef}
                            onKeyDown={(e) => {
                              if (e.key == 'Tab') {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.buttonRef.current.focus()
                              }
                            }}
                            required
                          />
                        </MDBCol>
                       
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md='6' className="pb-4 px-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                          <div>
                            <button type="button" className="rergistration-button modal-button green profile-edit"  onClick={!this.state.isPasswordchange ? this.onPasswordCahngeShow : this.onPasswordCahngeHide}>
                              <span>Password change</span>
                            </button>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      {this.state.isPasswordchange && (
                        <>
                         <MDBRow className="pb-2 m-0">
                            <MDBCol md='12'>
                              <label htmlFor="password">Old password</label>
                              <PasswordInput
                                id='password'
                                name='password'
                                type='password'
                                onChange={this.changeHandler}
                                value={this.state.password}
                                required
                              />
                            </MDBCol>
                            <MDBCol md='12'>
                              <label htmlFor="newPassword">New password</label>
                              <PasswordInput
                                id='newPassword'
                                name='newPassword'
                                type='password'
                                onChange={this.changeHandler}
                                value={this.state.newPassword}
                                required
                              />
                            </MDBCol>
                            <MDBCol md='12'>
                              <label htmlFor="confirmPassword">Re-enter new password</label>
                              <PasswordInput
                                id='confirmPassword'
                                name='confirmPassword'
                                type='password'
                                onChange={this.changeHandler}
                                value={this.state.confirmPassword}
                                required
                              />
                            </MDBCol>
                            <MDBRow className="pb-2 pt-3 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                                <div>
                                  <button type="button" className="rergistration-button modal-button green profile-edit"  onClick={this.onNewPassword}>
                                    <span>Save new password</span>
                                  </button>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBRow>
                        </>
                      )}
                      <MDBRow center className="py-2 mx-5">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0 elofizetes-col">   
                          <span>Management of prepayments</span>
                        </MDBCol>
                      </MDBRow>
                      {this.state.delete ? 
                      <>
                        <MDBRow className="pb-2 m-0">
                          <MDBCol md='12'  className="d-grid align-items-center justify-content-start ps-5">    
                            <div>
                                <span>Your profile will be deleted after your valid subscription expires.</span><br/>
                                <span>If you want to reset your profile click on the Reset my profile button!</span>
                            </div>
                            <div>
                              <button type="button" className="rergistration-button modal-button green" onClick={this.undeleteProfile} >
                                <span>Restore my profile</span>
                              </button>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </>:
                      <>
                       {this.state.paymentService && (
                        <>
                          {cookies.get('client')?.status !== "INACTIVE" && this.state.paymentService !== "NONE" ? 
                          <>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                                <div>
                                  <button type="button" className="rergistration-button modal-button green profile-edit" onClick={() => {this.props.context.closeLoginModal(); setTimeout(() => this.props.context.openLoginModal("fizeteslemondas"), 500); }} >
                                    <span>Cancel subscription</span>
                                  </button>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                                <div>
                                  <button type="button" className="rergistration-button modal-button green profile-edit" onClick={this.onNewCard}>
                                    <span>Kártyacsere</span>
                                  </button>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 pt-4 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-start ps-5">    
                                <div>
                                    <span style={{color: "rgba(67, 181, 174, 0.9)"}}>AKTIV ELŐFIZETÉS</span>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-start ps-5">    
                                <div>
                                    <span>You can use the video library without disturbance!</span>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </>:
                          <>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                                <div>
                                  <button type="button" className="rergistration-button modal-button green profile-edit" onClick={() => this.onNewSubscription("PAYPAL")}>
                                    <span>PayPal subscription</span>
                                  </button>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">    
                                <div>
                                  <button type="button" className="rergistration-button modal-button green profile-edit" onClick={() => this.onNewSubscription("SIMPLEPAY")}>
                                    <span>SimplePay subscription</span>
                                  </button>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 pt-4 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-start ps-5">    
                                <div>
                                    <span style={{color: "red"}}>INACTIVE SUBSCRIPTION</span>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pb-2 m-0">
                              <MDBCol md='12'  className="d-grid align-items-center justify-content-start ps-5">    
                                <div>
                                    <span>Please choose SimplePay or PayPal subscription to access all content!</span>
                                </div>
                              </MDBCol>
                              <MDBCol md="12">
                              <div className="d-flex align-items-center justify-content-center pt-4">
                                <img className="marker-image" src={marker} alt="marker"/>
                              </div>
                              </MDBCol>
                            </MDBRow>
                          </>
                          }
                        </>
                      )}
                      </>
                      }
                     
                     

                     {/* <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0" >    
                          <div>
                            <button type="button" className="rergistration-button modal-button green" onClick={this.onCancelCard}>
                              <span>Kártyaadatok törlése</span>
                            </button>
                          </div>
                        </MDBCol>
                      </MDBRow>*/}
                    </MDBCol>
                      </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6' size="6"  className="d-grid align-items-center justify-content-center p-0">    
                      <div>
                        <button ref={this.props.buttonRef} type="submit" className="rergistration-button modal-button green profile-edit" >
                        <span>SAVE</span>
                        </button>
                      </div>
                    </MDBCol>
                    <MDBCol md='6' size="6"  className="d-grid align-items-center justify-content-center p-0">    
                      <div>
                        <button type="button" onClick={() => {this.props.context.closeLoginModal(); setTimeout(() => this.props.context.openLoginModal("deleteProfile"), 500); }} className="rergistration-button modal-button black profile-edit" >
                          <span>Delete my profile</span>
                        </button>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </form>
      </>
    );
  }
};

function ProfileEditFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const buttonRef = useRef();
  const context = useContext(AppContext);
  return <ProfileEditForm searchParams={searchParams} 
                          navigate={navigate} 
                          hideModal={props.hideModal}
                          loginEvent={props.loginEvent}
                          firstnameRef={firstnameRef}
                          emailRef={emailRef}
                          passwordfirstRef={passwordfirstRef}
                          passwordtwoRef={passwordtwoRef}
                          nameRef={nameRef}
                          addressRef={addressRef}
                          postcodeRef={postcodeRef}
                          cityRef={cityRef}
                          countryRef={countryRef}
                          buttonRef={buttonRef}
                          hidePassword={hidePassword}
                          setHidePassword={setHidePassword}
                          hidePasswordtwo={hidePasswordtwo}
                          setHidePasswordtwo={setHidePasswordtwo}
                          context={context}
                          />;
}

export default ProfileEditFormFunc;
