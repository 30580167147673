// react imports
import React, { useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param infoIcon?: string (className)
 * @param title: string
 * @param text: string
 * @param buttonText?: string
 * @param buttonIconClass?: string
 * @param buttonClick?: () => {}
 * @returns
 */
const GeneralInfoModal = (props) => {
  return (
    <MDBModal
      className="altalanos-modal"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none'  type='button' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{props.title}</h3>

            <p>{props.text}</p>

            {props.buttonText && (
              <button
                className="jc-btn black"
                onClick={() => {
                  if (props.buttonClick) {
                    props.buttonClick();
                  }
                }}
              >
                {props.buttonIconClass && (
                  <i className={props.buttonIconClass}></i>
                )}

                {props.buttonText}
              </button>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default GeneralInfoModal;
